import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiErrorResponse, ApiSuccessResponse, ObjectWithUnknowProperty } from 'src/app/core/models/common.model';
import { ListMaterialSKUResponse } from 'src/app/core/models/material-sku.model';
import {
  BusinessPartnersResponse,
  ListMaterialData,
  MaterialDetailsResponse,
  MaterialReponse
} from 'src/app/core/models/material.model';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';

import { DeleteMemberResponse } from '../../../core/models/member.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  constructor(private http: HttpService) {}

  public searchMaterial({
    page = 0,
    limit = 25,
    ...rest
  }: ListMaterialData): Observable<ApiSuccessResponse<MaterialReponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`materials/search`, { page, limit, ...rest });
  }

  public bulkDelete(
    data: any
  ): Observable<ApiSuccessResponse<MaterialReponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.patch(`materials/bulk-delete`, data);
  }

  public getBusinessPartners(): Observable<
    ApiSuccessResponse<BusinessPartnersResponse | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/business-partners`, {}, environment.common_url);
  }

  public getBusinessAttributePartners(): Observable<
    ApiSuccessResponse<BusinessPartnersResponse | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/business-partners-attributes`, {}, environment.common_url);
  }

  public importDbMaterial(
    url: string,
    fieldName: string
  ): Observable<ApiSuccessResponse<DeleteMemberResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`materials/import`, { [fieldName]: url });
  }

  public importCSVMaterialSKU(
    url: string,
    fieldName: string
  ): Observable<ApiSuccessResponse<ListMaterialSKUResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`materials/sku/import`, { [fieldName]: url });
  }

  public getMaterialById(
    id: string
  ): Observable<ApiSuccessResponse<MaterialDetailsResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`materials/${id}`);
  }
  public getMaterialDetailsById(
    id: string
  ): Observable<ApiSuccessResponse<MaterialDetailsResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`materials/${id}/detail`);
  }
  public getSkuByMaterialId({
    materialId,
    limit = 1000,
    page = 0,
    orderBy = 'order:asc'
  }: {
    materialId: string;
    limit?: number;
    page?: number;
    orderBy?: string;
  }) {
    return this.http.get(`materials/${materialId}/sku?page=${page}&limit=${limit}&orderBy=${orderBy}`);
  }
  public deleteMaterialSKU(
    id: string
  ): Observable<ApiSuccessResponse<MaterialDetailsResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.delete(`materials/sku/${id}`);
  }

  public getMaterialMediaOrAttachment({
    materialId,
    materialHasAttachmentForInternal,
    materialHasAttachmentForShow,
    query
  }: {
    materialId: string;
    materialHasAttachmentForInternal?: string;
    materialHasAttachmentForShow?: string;
    query?: any;
  }): Observable<ApiSuccessResponse<MaterialDetailsResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    let path = `materials/${materialId}`;
    if (materialHasAttachmentForInternal) {
      path += '/internal-attachments';
    } else if (materialHasAttachmentForShow) {
      path += '/showroom-attachments';
    } else path += '/images-and-videos';
    if (query) {
      const params = new URLSearchParams(query).toString();
      path += `?${params}`;
    }
    return this.http.get(path);
  }

  public uploadMedia(payload: any): Observable<ApiSuccessResponse<ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.uploadSrm(`resources/upload-material-media`, payload);
  }

  public uploadAttachment(payload: any): Observable<ApiSuccessResponse<ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.uploadSrm(`resources/upload-attachment`, payload);
  }

  public getFileInfo(payload: any): Observable<ApiSuccessResponse<any> | ApiErrorResponse> {
    return this.http.post(`resources/file-info`, payload);
  }

  public createMaterial(payload: any): Observable<ApiSuccessResponse<ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`materials`, payload);
  }

  public getMaterialTags({ path, id }: any) {
    return this.http.get(`tags/${path}/${id}`);
  }

  public getMaterialAllTags(id: string) {
    return this.http.get(`tags/material/${id}`);
  }

  public updateMaterial(
    id: string,
    payload: any
  ): Observable<ApiSuccessResponse<ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.put(`materials/${id}`, payload);
  }

  public deleteMaterial(id: string) {
    return this.http.delete(`materials/${id}`);
  }

  public getPicForSearchMaterial({
    page = 0,
    limit = 25,
    orderBy = 'memberName:desc'
  }): Observable<ApiSuccessResponse<ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`materials/get-pic?page=${page}&limit=${limit}&orderBy=${orderBy}`);
  }
}
