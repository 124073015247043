import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';
import { FILE_TYPE, UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { FormAbstractComponent } from 'src/app/shared/abstracts/form.abstract';

import { BannerDirective } from '../../directives/dialog-banner.directive';
import { MaterialService } from 'src/app/modules/db-material/services/material.service';
import { firstValueFrom } from 'rxjs';

type DialogData = {
  isUploadMultiple?: boolean;
  maxTotalFiles?: number;
  isUploadMedia?: boolean;
  allowMimes?: string[];
  maxFileSize?: number;
  maxTotalFileSize?: number;
  totalFileSize?: number;
};

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent extends FormAbstractComponent implements OnInit {
  @ViewChild(BannerDirective) banner: BannerDirective;
  _form!: FormGroup;
  URL_TYPE = {
    REFERENCE: 2,
    DOWNLOAD: 3
  };
  messageUrlError = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    public translate: TranslateHelper,
    private fb: FormBuilder,
    private utils: UtilsHelper,
    private material: MaterialService
  ) {
    super();
    this._form = this.fb.group({
      urlType: [this.URL_TYPE['REFERENCE']],
      url: [null, [Validators.pattern(/^(http|https):\/\/[^ "]+$/), Validators.maxLength(255)]],
      file: [null]
    });
  }

  ngOnInit(): void {
    if (!this.data) {
      this.data = {
        isUploadMultiple: false,
        maxTotalFiles: 1,
        isUploadMedia: false,
        allowMimes: FILE_TYPE
      };
    }
  }

  async onSave() {
    this._form.markAllAsTouched();
    const firstError: string = this.getFirstError(this._form.controls);
    if (this._form.invalid) {
      if (firstError.includes('patternError')) {
        this.messageUrlError = this.translate.__t('uploadFileDialog.urlInvalid');
      } else if (firstError.includes('maxlengthError')) {
        if (this._form.get('urlType')?.value === this.URL_TYPE['REFERENCE']) {
          this.messageUrlError = this.translate.__t('uploadFileDialog.urlReferMaxLength');
        } else if (this._form.get('urlType')?.value === this.URL_TYPE['DOWNLOAD']) {
          this.messageUrlError = this.translate.__t('uploadFileDialog.urlDownloadMaxLength');
        }
      }
      return;
    }

    const data = this._form.getRawValue();

    if (!data.file && !data.url) {
      this.banner.error(this.translate.__m('ERR-COM-0012'));
      return;
    }

    if (data?.urlType === this.URL_TYPE.DOWNLOAD && this.data.maxFileSize) {
      // get filesize from url
      try {
        const fileInfo = await firstValueFrom(this.material.getFileInfo(data));
        if (fileInfo?.data?.size > this.data.maxFileSize) {
          this.banner.error(this.translate.__m('ERR-OPUR-0007'));
          return;
        }
      } catch (error) {
        this.banner.error(this.translate.__m('ERR-DMAT-0009'));
        return;
      }
    }

    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  handleDrop(event: any) {
    event.preventDefault();

    const files = event.dataTransfer.files;
    if (!files.length) return;

    this.processFile(files, event);
  }

  handleFileChange(event: any) {
    const files = event.target.files;

    if (!files.length) return;

    this.processFile(files, event);
  }

  processFile(files: any, event: any) {
    const isValidName = this.validateFileName(files);

    if (!isValidName) {
      event.target.value = '';
      return;
    }

    if (this.data?.isUploadMultiple && files.length > this.data.maxTotalFiles!) {
      this.showErrorMessageAndReset('ERR-DMAT-0006', event);
      return;
    }

    const isValidType = Array.from(files).every((file: any) =>
      this.utils.validateFileType(file, this.data?.allowMimes)
    );

    if (!isValidType && this.data?.isUploadMedia) {
      this.showErrorMessageAndReset('ERR-DMAT-0005', event);
      return;
    }

    if (this.data?.isUploadMultiple) {
      if (this.data.maxFileSize) {
        const isOverSize = Array.from(files).some((file: any) => file.size > this.data.maxFileSize!);
        if (isOverSize) {
          this.showErrorMessageAndReset('ERR-OPUR-0007', event);
          return;
        }
      }

      if (this.data.maxTotalFileSize) {
        const totalSize =
          Array.from(files).reduce((acc: number, file: any) => acc + file.size, 0) + (this.data?.totalFileSize || 0);
        if (totalSize > this.data.maxTotalFileSize!) {
          this.showErrorMessageAndReset('ERR-DMAT-0004', event);
          return;
        }
      }
    } else {
      if (this.data.maxFileSize) {
        if (files[0].size > this.data.maxFileSize!) {
          this.showErrorMessageAndReset('ERR-OPUR-0007', event);
          return;
        }
      }

      if (this.data.maxTotalFileSize) {
        if (files[0].size + (this.data?.totalFileSize || 0) > this.data.maxTotalFileSize!) {
          this.showErrorMessageAndReset('ERR-DMAT-0004', event);
          return;
        }
      }
    }

    this._form.patchValue({
      file: this.data?.isUploadMultiple ? files : files[0]
    });
  }

  validateFileName(files: any): boolean {
    for (const file of files) {
      const isValid = this.utils.validateStringLength(file.name, 128, 64);
      if (!isValid) {
        this.banner.error(this.translate.__m('ERR-COM-0016'));
        return false;
      }
    }
    return true;
  }

  showErrorMessageAndReset(message: string, event: any) {
    event.target.value = '';
    this.banner.error(this.translate.__m(message));
  }

  handleDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
