import { Injectable } from '@angular/core';
import { LoggedUserResponse } from '../models/auth.model';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/app/core/models/common.model';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant.model';
import { CookieService } from 'ngx-cookie-service';
import { decodeJwt } from 'jose';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public loggedUser = new BehaviorSubject<string>('');
  private tenantObserver = new BehaviorSubject<string>('');
  private domains: Tenant[] = [];

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    this.loggedUser.next('{}');
  }

  public get(): string {
    return localStorage.getItem('jwt_token') || '';
  }

  public set(token: string): void {
    if (token == '') {
      return localStorage.removeItem('jwt_token');
    }

    return localStorage.setItem('jwt_token', token);
  }

  public getUser(): string {
    return localStorage.getItem('logged_username') || '{}';
  }

  public getUserObservable(): Observable<string> {
    return this.loggedUser;
  }

  public setUser(user: LoggedUserResponse): void {
    if (user.email == '') {
      return localStorage.removeItem('logged_username');
    }

    const userJson = JSON.stringify(user);
    this.loggedUser.next(userJson);

    return localStorage.setItem('logged_username', userJson);
  }

  public removeToken(): void {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('logged_username');
    localStorage.removeItem('tenant_id');
  }

  public hasLogged(): boolean {
    let token = this.get();

    if (!token) {
      token = this.cookieService.get('jwt_token');
      const tenantId = this.cookieService.get('tenant_id');

      this.set(token);

      if (token && tenantId) {
        this.httpClient
          .put<ApiResponse>(
            `${environment.common_url}/auth/update-last-login`,
            {},
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'x-tenant-id': tenantId
              })
            }
          )
          .subscribe();
      }
    }

    return token !== '';
  }

  public getTenant() {
    return this.tenantObserver.value;
  }

  public setTenant(): Observable<any> {
    return this.httpClient
      .get<ApiResponse>(`${environment.common_url}/tenant/config/domains`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.get()}`
        })
      })
      .pipe(
        map((rs) => {
          this.domains = rs.response as any;
          const tenantId = this.getTenantId();
          this.tenantObserver.next(tenantId);
          return tenantId;
        }),
        catchError((error) => {
          if (error.error?.status == 401) {
            this.removeToken();
            location.href = '/auth/login';
          }
          return throwError(() => {
            return {
              success: false,
              ...error.error
            };
          });
        })
      );
  }

  public getDirectUrl(): string {
    return localStorage.getItem('direct_url') || '';
  }

  public setDirectUrl(url: string): void {
    return localStorage.setItem('direct_url', url);
  }

  public removeDirectUrl(): void {
    localStorage.removeItem('direct_url');
  }

  public setCodeVerifyChangeMail(code: string): void {
    return localStorage.setItem('verify_code_change_mail', code);
  }

  public getCodeVerifyChangeMail() {
    return localStorage.getItem('verify_code_change_mail') || '';
  }

  public removeCodeVerifyChangeMail() {
    return localStorage.removeItem('verify_code_change_mail');
  }

  public getTenantLogo(): string {
    return localStorage.getItem('tenant_logo') || '';
  }

  public setTenantLogo(url: string): void {
    return localStorage.setItem('tenant_logo', url);
  }

  private getTenantId() {
    const subDomain = window.location.hostname.split('.')[0];
    return this.domains.find((x) => x.subDomain === subDomain)?.id ?? '';
  }

  public getSubdomain() {
    return this.domains;
  }

  public canManageMaterial(): boolean {
    const user = this.getUser();
    const currentTenantId = this.getTenant();
    if (user) {
      const userParsed = JSON.parse(user);
      if (!userParsed?.isSystemAdmin) {
        const tenant = userParsed?.tenants?.find((item: any) => item.id === currentTenantId) || [];

        const hasPrivilege = tenant?.role === 1 || tenant?.authorityGroups?.some((item: any) => item.canManageMaterial);

        return hasPrivilege;
      }

      return true;
    }

    return false;
  }
  public canManageShowroom(): boolean {
    const user = this.getUser();
    const currentTenantId = this.getTenant();
    if (user) {
      const userParsed = JSON.parse(user);
      if (!userParsed?.isSystemAdmin) {
        const tenant = userParsed?.tenants?.find((item: any) => item.id === currentTenantId) || [];

        const hasPrivilege = tenant?.role === 1 || tenant?.authorityGroups?.some((item: any) => item.canManageShowroom);

        return hasPrivilege;
      }

      return true;
    }

    return false;
  }
  public checkCanManageShowroomWithAuthGroup(showroomAuthGroups: string[]): boolean {
    const user = this.getUser();
    const currentTenantId = this.getTenant();
    if (user) {
      const userParsed = JSON.parse(user);
      if (!userParsed?.isSystemAdmin) {
        const tenant = userParsed?.tenants?.find((item: any) => item.id === currentTenantId) || [];

        const hasPrivilege =
          tenant?.role === 1 ||
          tenant?.authorityGroups?.some((item: any) => item.canManageShowroom && showroomAuthGroups.includes(item.id));

        return hasPrivilege;
      }

      return true;
    }

    return false;
  }

  public hasAuthorityGroups(): boolean {
    const user = this.getUser();
    const currentTenantId = this.getTenant();
    if (user) {
      const userParsed = JSON.parse(user);
      if (!userParsed?.isSystemAdmin) {
        const tenant = userParsed?.tenants?.find((item: any) => item.id === currentTenantId) || [];

        const hasPrivilege = tenant?.role === 1 || tenant?.authorityGroups?.length;

        return hasPrivilege;
      }

      return true;
    }

    return false;
  }

  public getTenantGroup() {
    const user = JSON.parse(this.getUser()) || {};
    if (!user.tenants?.length) return { fullAccess: user.isSystemAdmin, group: [], isAdmin: false };
    return {
      group: user.tenants,
      fullAccess: user.isSystemAdmin,
      isAdmin: user.tenants.some((tenant: any) => tenant.role === 1)
    };
  }

  public removeAllLocalStorageSetKeyByEmail() {
    const user = JSON.parse(this.getUser()) || {};
    if (!user.email) return;

    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      if (key.includes(user.email)) {
        localStorage.removeItem(key);
      }
    });
  }

  isTokenExpired() {
    const token = this.get();

    if (!token) return false;

    try {
      const tokenInfo: any = decodeJwt(token);

      const currentTime = Date.now() / 1000;

      if (!!tokenInfo?.exp && tokenInfo?.exp < currentTime) return true;

      return false;
    } catch (error) {
      return true;
    }
  }
}
