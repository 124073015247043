@if (!readAsLabel) {
<div
  class="input"
  [ngClass]="className"
>
  <input
    *ngIf="isApplyTrackerElement"
    appElementTracker
    class="form-input outline-none w-full"
    [ngClass]="{
      'form-input-label': readAsLabel,
      'form-input-border': isBorder,
    }"
    [readOnly]="readAsLabel"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    (keypress)="onHandleKeyPress($event)"
    [placeholder]="placeHolder"
    (blur)="onHandleBlur($event)"
    (focus)="onHandleFocus($event)"
    [disabled]="isDisabled"
  />

  <input
    *ngIf="!isApplyTrackerElement"
    class="form-input outline-none w-full"
    [ngClass]="{
      'form-input-label': readAsLabel,
      'form-input-border': isBorder,
    }"
    [readOnly]="readAsLabel"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    (keypress)="onHandleKeyPress($event)"
    [placeholder]="placeHolder"
    (blur)="onHandleBlur($event)"
    (focus)="onHandleFocus($event)"
    [disabled]="isDisabled"
  />
</div>
} @else {
<span
  class="px-[10px] text-over-line"
  [matTooltip]="value"
  matTooltipPosition="below"
  matTooltipClass="custom-tooltip"
  >{{ value }}</span
>
}
