import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { EditStatusService } from 'src/app/core/services/edit-status.service';

@Directive({
  selector: '[appElementTracker]'
})
export class ElementTrackerDirective implements OnInit {
  @Input() disableTracker: boolean = false;

  constructor(private el: ElementRef, private editStatusService: EditStatusService) {}

  ngOnInit() {
    if (this.disableTracker) {
      return;
    }
    this.el.nativeElement.addEventListener('click', this.onChangeEditStatus.bind(this));
    this.el.nativeElement.addEventListener('focus', this.onChangeEditStatus.bind(this));
    this.el.nativeElement.addEventListener('change', this.onChangeEditStatus.bind(this));
  }

  onChangeEditStatus() {
    if (this.disableTracker) {
      return;
    }
    this.editStatusService.setEditingStatus(true);
  }
}
