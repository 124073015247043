/* eslint-disable no-irregular-whitespace */
import { Injectable } from '@angular/core';

import { TenantResponse } from '../models/tenant.model';
import { TokenService } from '../services/token.service';
import { Role } from '../models/auth.model';
import * as IMask from 'imask';
import * as moment from 'moment';
import { TenantMasterService } from '../services/tenant-master.service';

export enum RoundingType {
  SKU_DETAIL = 1,
  TOTAL = 2
}

export enum RoundingDetailType {
  ROUND_DOWN = 1,
  ROUND_UP = 2,
  ROUND_OFF = 3
}

export enum SearchCondition {
  AND = 'must',
  OR = 'should'
}

export enum NameSpaceSocket {
  ANNOUNCEMENT = 'announcement'
}

export const paginationSizes = [30, 100, 200];

export enum SocketEvent {
  NEW_ANNOUNCEMENT = 'new-announcement'
}

export enum SkyType {
  TRANSPARENCY = 0,
  CUSTOMIZE = 1
}

export const FILE_TYPE = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'postscript', 'pdf', 'mp4'];

export const MAX_FILE_SIZE = 10485760;
export const MAX_TOTAL_FILE_SIZE = 3 * 10485760;

@Injectable({
  providedIn: 'root'
})
export class UtilsHelper {
  callbackFunc: ReturnType<typeof setTimeout>;
  constructor(private tokenService: TokenService, private tenantService: TenantMasterService) {}

  checkValidOrResetPagination(limit: number) {
    if (!paginationSizes.includes(limit)) {
      return paginationSizes[1];
    }
    return limit;
  }

  isAbsolutedUrl = (path: string): boolean => {
    return new RegExp('^(?:[a-z+]+:)?//', 'i').test(path);
  };

  convertToHalf(val: any) {
    return val.toString().replace(/[！-～]/g, (halfWidthChar: any) => {
      return String.fromCharCode(halfWidthChar.charCodeAt(0) - 0xfee0);
    });
  }

  inputMask = (mask?: any, thousandsSeparator?: any) => {
    return {
      mask: mask ?? Number,
      signed: true, // Allow Negative
      scale: 3,
      max: 999999999999999,
      min: -99999999999999,
      padFractionalZeros: false,
      thousandsSeparator: thousandsSeparator ?? ',',
      radix: '.',
      lazy: false // make placeholder always visible
    };
  };

  dateMask = (formatDate = 'YYYY/MM/DD') => {
    return {
      mask: Date,
      lazy: true,
      pattern: formatDate,
      format: (date: any) => moment(date).format(formatDate),
      parse: (str: any) => moment(str, formatDate),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1970,
          to: 9999
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        },
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        }
      }
    };
  };

  roundNumber(roundingType: RoundingType, value: number, tenant: TenantResponse, currencyCode?: string): number {
    let keyRoundingDetailType: keyof TenantResponse = 'roundingDetailType';
    let keyRoundingNumber: keyof TenantResponse = 'roundingDetailNumber';

    if (tenant?.tenantRounding?.length) {
      const rounding = tenant.tenantRounding.find(
        (round: any) =>
          round.activeStatus === '1' && round.currencyCode === currencyCode && round.roundingType === roundingType
      );

      if (rounding) {
        // 10^0 = 1, 10^1 = 10, 10^2 = 100, 10^3 = 1000....
        const pow = Math.pow(10, rounding?.[keyRoundingNumber] - 1);

        switch (rounding?.[keyRoundingDetailType]) {
          case RoundingDetailType.ROUND_DOWN:
            return Math.floor(value * pow) / pow;
          case RoundingDetailType.ROUND_UP:
            return Math.ceil(value * pow) / pow;
          case RoundingDetailType.ROUND_OFF:
            return Math.round(value * pow) / pow;
        }
      }
    }

    keyRoundingDetailType = 'roundingTotalType';
    keyRoundingNumber = 'roundingTotalNumber';

    const pow = Math.pow(10, tenant?.[keyRoundingNumber] - 1);
    switch (tenant?.[keyRoundingDetailType]) {
      case RoundingDetailType.ROUND_DOWN:
        return Math.floor(value * pow) / pow;
      case RoundingDetailType.ROUND_UP:
        return Math.ceil(value * pow) / pow;
      case RoundingDetailType.ROUND_OFF:
        return Math.round(value * pow) / pow;
    }

    return 0;
  }

  utcDate(date: any): string | undefined {
    if (!date) return undefined;

    return new Date(new Date(new Date(date).setHours(0, 0, 0, 0)).toString().split('GMT')[0] + ' UTC').toISOString();
  }

  // start open search
  keyNested = [
    'businessPartners',
    'businessPartnersSecrets',
    'materialType4.sizeStrings',
    'materialType5.sizeStrings',
    'materialType5.processingTypes',
    'materialType6.sizeStrings',
    'materialType7.sizeStrings',
    'materialType7.processingTypes',
    'materialType8.sizeStrings',
    'materialType8.processingTypes',
    'materialType9.sizeStrings',
    'materialType10.sizeStrings',
    'materialType11.sizeStrings',
    'materialType11.processingTypes',
    'materialHasSku.skuList'
  ];

  // check key is query nested
  isKeyInKeyNested(key: string) {
    let result = {
      path: '',
      isKeyNested: false
    };
    const keyParts = key.split('.');
    for (const nestedKey of this.keyNested) {
      const nestedKeyParts = nestedKey.split('.');
      let isKeyInNested = true;
      for (let i = 0; i < nestedKeyParts.length; i++) {
        if (keyParts[i] !== nestedKeyParts[i]) {
          isKeyInNested = false;
          break;
        }
      }

      if (isKeyInNested) {
        result = {
          path: nestedKey,
          isKeyNested: true
        };
        return result;
      }
    }
    result = {
      path: '',
      isKeyNested: false
    };
    return result;
  }

  // convert query
  keyQuery = (key: any, value: any, type?: any, tabKey?: string, data?: any) => {
    let newKey = key;
    let newValue = value;

    let queryType = type;

    if (tabKey) {
      newKey = `${tabKey}.${key}`;
    }

    if (key === 'keyword') {
      return null;
    }
    if (key === 'materialNo' || key === 'name' || key === 'id' || key === 'materialCode') {
      queryType = 'wildcard';
      newValue = value ? `*${value}*` : '';
      newKey = `${newKey}.keyword`;
      if (newKey && value) {
        return {
          [queryType]: {
            [newKey]: {
              value: `*${value}*`,
              case_insensitive: true
            }
          }
        };
      }
      return null;
    }

    if (value && key === 'personInCharge.personInChargeValue') {
      return {
        wildcard: {
          'personInCharge.personInChargeValue': {
            value: `*${value}*`,
            case_insensitive: true
          }
        }
      };
    }

    if (key === 'buyer.mTenantUnitForSalesAndPurchasesUnitPrice.mSystemUnitIdForSalesAndPurchasesUnitPrice') {
      if (!value?.length) return null;

      return {
        bool: {
          should: value.map((item: any) => ({
            [queryType]: {
              'mTenantUnitForSalesAndPurchasesUnitPrice.mSystemUnitIdForSalesAndPurchasesUnitPrice': item
            }
          }))
        }
      };
    }

    if (newKey.match(/(From)$/)) {
      const keyRange = key.slice(0, key.indexOf('From'));
      newKey = tabKey ? `${tabKey}.${keyRange}` : keyRange;
      queryType = 'range';

      let from = value;
      let to = data[keyRange + 'To'];

      const fromX = value;
      const toX = data[keyRange + 'To'];

      if (keyRange.match(/(mSystemSizeStringValue)/)) {
        from = fromX > toX ? toX : fromX;
        to = fromX > toX ? fromX : toX;

        from = this.isNumber(from) ? from : this.searchTenantSizeValue(from);
        to = this.isNumber(to) ? to : this.searchTenantSizeValue(to);
      }

      newValue = {};
      if (from) {
        newValue = {
          gte: from
        };
      }
      if (to) {
        newValue = {
          ...newValue,
          lte: to
        };
      }
    } else if (newKey.match(/(To)$/)) {
      let keyRange = key.slice(0, key.indexOf('To'));
      if (newKey.match('minimumOrderLotForTotal')) {
        keyRange = 'minimumOrderLotForTotal';
      }
      if (!data[keyRange + 'From']) {
        newKey = tabKey ? `${tabKey}.${keyRange}` : keyRange;
        queryType = 'range';

        if (keyRange.match(/(mSystemSizeStringValue)/)) {
          newValue = {
            lte: this.searchTenantSizeValue(data[keyRange + 'To'])
          };
        } else
          newValue = {
            lte: data[keyRange + 'To']
          };
      } else return null;
    }

    if (key.match(/(minimumSize)/) || key.match(/(maximumSize)/)) {
      const sizeQuery = this.convertSizeQuery(key, tabKey, data);

      if (sizeQuery.length) {
        return {
          bool: {
            should: [...sizeQuery]
          }
        };
      } else {
        return null;
      }
    }

    if (newKey && value && newValue) {
      const checkKeyNested = this.isKeyInKeyNested(newKey);
      if (checkKeyNested?.isKeyNested) {
        return {
          nested: {
            path: checkKeyNested.path,
            query: {
              [queryType]: {
                [newKey]: newValue
              }
            },
            ignore_unmapped: true
          }
        };
      } else {
        return {
          [queryType]: {
            [newKey]: newValue
          }
        };
      }
    }

    return null;
  };

  // convert query minimumSize, maximunSize
  convertSizeQuery(key: string, tabKey: any, data: any) {
    let query: any[] = [];

    const valueMinimumSize = data['minimumSize'];
    const valueMaximumSize = data['maximumSize'];

    const keyMinimum = `${tabKey}.minimumSize`;
    const keyMaximum = `${tabKey}.maximumSize`;

    if (
      (key.match(/(minimumSize)/) && valueMinimumSize) ||
      (key.match(/(maximumSize)/) && valueMaximumSize && !valueMinimumSize)
    ) {
      query = this.handleSizeQuery(valueMinimumSize, valueMaximumSize, keyMinimum, keyMaximum);
    }

    return query;
  }

  handleSizeQuery(valueMinimumSize: any, valueMaximumSize: any, keyMinimum: string, keyMaximum: string) {
    const query = [];
    if (valueMinimumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMinimumSize
                }
              }
            }
          ]
        }
      });

      if (!valueMaximumSize) {
        query.push({
          bool: {
            must: [
              {
                range: {
                  [keyMinimum]: {
                    gte: valueMinimumSize
                  }
                }
              }
            ]
          }
        });
      }
    }
    if (valueMaximumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMaximumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMaximumSize
                }
              }
            }
          ]
        }
      });

      if (!valueMinimumSize) {
        query.push({
          bool: {
            must: [
              {
                range: {
                  [keyMaximum]: {
                    lte: valueMaximumSize
                  }
                }
              }
            ]
          }
        });
      }
    }
    if (valueMinimumSize && valueMaximumSize) {
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  gte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  lte: valueMaximumSize
                }
              }
            }
          ]
        }
      });
      query.push({
        bool: {
          must: [
            {
              range: {
                [keyMinimum]: {
                  lte: valueMinimumSize
                }
              }
            },
            {
              range: {
                [keyMaximum]: {
                  gte: valueMaximumSize
                }
              }
            }
          ]
        }
      });
    }

    return query;
  }

  // convert condition and product info
  queryObjectConvert = (data: any, seriesList?: any[], skuIds?: string[], filterSkuValid?: boolean, filter?: any) => {
    const result: any = [];
    let isComposition = false;

    if (Object.keys(data)?.length) {
      for (const key in data) {
        if (filterSkuValid) {
          if (key?.includes('materialHasSku.skuList.')) {
            filter.sku = {
              ...filter.sku,
              [key?.replace('materialHasSku.skuList.', '')]: data[key] === null ? undefined : data[key]
            };
            continue;
          }
        }

        if (key === 'materialHasSku.skuList.skuKeyword') {
          continue;
        }

        if (key !== 'orderBy' && !key.match(/(MaxRange)$/)) {
          if (key.match(/(Composition)/)) {
            if (!isComposition) {
              isComposition = true;

              const firstMaterialComposition = data['firstMaterialComposition'];
              const firstCompositionMixedRatio = data['firstCompositionMixedRatio'];
              const secondMaterialComposition = data['secondMaterialComposition'];
              const secondCompositionMixedRatio = data['secondCompositionMixedRatio'];
              const shouldQueryCompotion: any[] = [];
              const mustQueryCompotion1: any[] = [];
              const mustQueryCompotion2: any[] = [];

              const keyId = 'totalMaterialCompositionMixRatio.mTenantMaterialCompositionId.keyword';
              const keyRadio = 'totalMaterialCompositionMixRatio.mixedRatio';

              if (firstMaterialComposition) {
                const compotionQuery = this.keyQuery(keyId, data['firstMaterialComposition'], 'match_phrase');
                compotionQuery !== null && mustQueryCompotion1.push(compotionQuery);
              }
              if (firstCompositionMixedRatio) {
                if (data['firstCompositionMixedRatio']) {
                  const result = [];

                  result.push({
                    range: {
                      [keyRadio]: {
                        gte: data['firstCompositionMixedRatio']
                      }
                    }
                  });

                  if (firstMaterialComposition) {
                    result.push({
                      bool: {
                        must_not: {
                          exists: {
                            field: keyRadio
                          }
                        }
                      }
                    });
                  }

                  const radioQuery = {
                    bool: {
                      should: [...result]
                    }
                  };
                  mustQueryCompotion1.push(radioQuery);
                }
              }

              if (secondMaterialComposition) {
                const compotionQuery = this.keyQuery(keyId, data['secondMaterialComposition'], 'match_phrase');
                compotionQuery !== null && mustQueryCompotion2.push(compotionQuery);
              }
              if (secondCompositionMixedRatio) {
                if (data['secondCompositionMixedRatio']) {
                  const result = [];

                  result.push({
                    range: {
                      [keyRadio]: {
                        gte: data['secondCompositionMixedRatio']
                      }
                    }
                  });

                  if (secondMaterialComposition) {
                    result.push({
                      bool: {
                        must_not: {
                          exists: {
                            field: keyRadio
                          }
                        }
                      }
                    });
                  }

                  const radioQuery = {
                    bool: {
                      should: [...result]
                    }
                  };
                  mustQueryCompotion2.push(radioQuery);
                }
              }

              if (mustQueryCompotion1.length) {
                shouldQueryCompotion.push({
                  nested: {
                    path: 'totalMaterialCompositionMixRatio',
                    query: {
                      bool: {
                        must: mustQueryCompotion1
                      }
                    },
                    ignore_unmapped: true
                  }
                });
              }
              if (mustQueryCompotion2.length) {
                shouldQueryCompotion.push({
                  nested: {
                    path: 'totalMaterialCompositionMixRatio',
                    query: {
                      bool: {
                        must: mustQueryCompotion2
                      }
                    },
                    ignore_unmapped: true
                  }
                });
              }

              if (shouldQueryCompotion.length) {
                result.push({
                  bool: {
                    must: shouldQueryCompotion
                  }
                });
              }
            }
          } else {
            if (key === 'mTenantSeries.mTenantSeriesId') {
              const seriesQuery = this.handleSeries(seriesList, data[key]);

              if (seriesQuery?.length) {
                result.push({
                  bool: {
                    should: seriesQuery
                  }
                });
              }
            } else {
              if (!(skuIds && (key.match(/(bulkPrice)/) || key.match(/(costOfGoodsPurchased)/)))) {
                const query = key.match(/(Id)/) ? 'match_phrase' : 'match';
                const attributeQuery = this.keyQuery(key, data[key], query, '', data);
                attributeQuery !== null && result.push(attributeQuery);
              }
            }
          }
        }
      }
    }

    return result;
  };

  // convert tab categories
  shouldQueryCategoriesConvert = (data: any) => {
    const result: any = [];
    for (const key in data) {
      if (data[key].length) {
        for (const item of data[key]) {
          const attributeQuery = this.keyQuery(`${key}.keyword`, item, 'match_phrase');
          attributeQuery && result.push(attributeQuery);
        }
      }
    }
    return result;
  };

  // convert tab tag
  shouldQueryTagConvert = (data: any) => {
    const result: any = [];
    for (const key in data) {
      const querys = [];
      if (data[key].length) {
        for (let i = 0; i < data[key].length; i++) {
          const tag = data[key][i];

          const attributeQuery = {
            match_phrase: {
              [`${key}${key === 'precautionsSearchTags' ? '.mTenantPrecautionsTagId' : '.mTenantTagId'}`]: tag
            }
          };

          attributeQuery && querys.push(attributeQuery);
        }

        if (querys.length) {
          result.push({
            nested: {
              path: key,
              query: {
                bool: {
                  should: querys
                }
              },
              ignore_unmapped: true
            }
          });
        }
      }
    }

    return result;
  };

  // convert tab color
  shouldQueryColorConvert = (data: any) => {
    const result: any = [];

    for (let i = 0; i < data?.length; i++) {
      const color = data[i];

      const attributeQuery = {
        match_phrase: {
          'materialHasSku.skuList.mTenantColorId': color
        }
      };

      attributeQuery && result.push(attributeQuery);
    }

    return result;
  };

  // convert 11 tab common search
  listTabsQueryConvert = (listTabs: any) => {
    const result = [];
    for (const tabKey in listTabs) {
      if (tabKey !== 'product') {
        const objectTab: { [key: string]: any } = listTabs[tabKey];
        const objectResult: any = [];

        if (Object.keys(objectTab)?.length) {
          for (const key in objectTab) {
            if (
              key !== 'firstFiberFinenessFrom' &&
              key !== 'firstFiberFinenessTo' &&
              key !== 'secondFiberFinenessFrom' &&
              key !== 'secondFiberFinenessTo' &&
              key !== 'firstFiberFinenessTypeD' &&
              key !== 'secondFiberFinenessTypeD' &&
              !key.match(/(MaxRange)/)
            ) {
              const query = key.match(/(Id)/) || key === 'id' ? 'match_phrase' : 'match';
              const attributeQuery = this.keyQuery(key, objectTab[key], query, tabKey, objectTab);
              attributeQuery !== null && objectResult.push(attributeQuery);
            }
          }
        }

        if (objectResult.length) {
          result.push({
            bool: {
              must: objectResult
            }
          });
        }
      }
    }
    return result;
  };

  convertParamsOpenSearch(
    data: any,
    user: any,
    seriesList: any[],
    skuIds?: string[],
    filterSkuValid?: boolean,
    listOrg?: string[],
    ignoreCheckOrg: boolean = false
  ) {
    let params = null;
    const filter = {
      colors: [],
      sku: {}
    };
    const query: any = { must: [] };

    const condition = data.condition;
    const materialCategories = data.advancedSearch?.materialCategories;
    const commonSearch = data.advancedSearch?.commonSearchItems;
    const commonSearchProduct = data.advancedSearch?.commonSearchItems?.product;
    const tags = data.advancedSearch?.tags;
    const colors = data.advancedSearch?.colors;

    if (condition && Object.keys(condition).length) {
      if (condition.keyword || commonSearchProduct?.['materialHasSku.skuList.skuKeyword']) {
        const newKeyword = condition.keyword || commonSearchProduct?.['materialHasSku.skuList.skuKeyword'];
        const queryKeyword = this.handKeyword(newKeyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }

      const mustQueryCondition = this.queryObjectConvert(condition);
      query['must'].push(...mustQueryCondition);
    }

    if (materialCategories) {
      const shouldQueryCategory = this.shouldQueryCategoriesConvert(materialCategories);
      if (shouldQueryCategory.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCategory
          }
        });
      }
    }

    if (commonSearchProduct && Object.keys(commonSearchProduct).length) {
      const mustQueryProduct = this.queryObjectConvert(commonSearchProduct, seriesList, skuIds, filterSkuValid, filter);
      query['must'].push(...mustQueryProduct);
    }

    if (commonSearch && Object.keys(commonSearch).length) {
      const shouldQueryCommon = this.listTabsQueryConvert(commonSearch);
      if (shouldQueryCommon.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCommon
          }
        });
      }
    }

    if (tags) {
      const queryTags = this.shouldQueryTagConvert(tags);
      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }
    if (!skuIds?.length && colors && colors.length) {
      filter.colors = colors;
    }

    if (skuIds && skuIds.length) {
      const queryValidFrom = this.querySKUValidFrom(skuIds, commonSearchProduct, colors);
      if (queryValidFrom) {
        query['must'].push({
          terms: {
            _id: skuIds
          }
        });
      }
    }

    if (!ignoreCheckOrg) {
      const authQuery = this.handleAuthority(user, listOrg);
      if (authQuery?.length) {
        query['must'].push({
          bool: {
            should: authQuery
          }
        });
      }
    }

    params = {
      limit: data.limit,
      page: data.page,
      query: {
        bool: {
          ...query
        }
      },
      filter,
      selectedFields: [
        'id',
        'tenantId',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'mTenantMaterialCategory1',
        'mTenantMaterialCategory2',
        'mTenantMaterialCategory3',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason',
        'personInCharge',
        'materialHasSku',
        'mTenantCurrency'
      ]
    };

    const orderBy = data.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    return params;
  }

  convertSalerParamsOpenSearch(
    data: any,
    user: any,
    seriesList: any[],
    skuIds?: string[],
    ignoreCheckOrg: boolean = false
  ) {
    let params = null;
    const query: any = { must: [] };

    const condition = data?.condition;
    const materialCategories = data?.advancedSearch?.materialCategories;
    const commonSearch = data?.advancedSearch?.commonSearchItems || {};
    const searchProduct = data?.advancedSearch?.commonSearchItems?.product;
    const tags = data?.advancedSearch?.tags;
    const colors = data?.advancedSearch?.colors;

    if (condition && Object.keys(condition).length) {
      if (condition.keyword) {
        const queryKeyword = this.handKeyword(condition.keyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }

      const mustQueryCondition = this.queryObjectConvert(condition);
      query['must'].push(...mustQueryCondition);
    }

    if (materialCategories) {
      const shouldQueryCategory = this.shouldQueryCategoriesConvert(materialCategories);
      if (shouldQueryCategory.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCategory
          }
        });
      }
    }

    // Convert sku and showroom filter for RDB
    let skuFilter = {};
    let showroomMaterialFilter = {};

    const {
      bulkPriceBelowMinimumFrom,
      bulkPriceBelowMinimumTo,
      bulkPriceBelowMinimumMaxRange,
      inventoryQuantityFrom,
      inventoryQuantityTo,
      inventoryQuantityMaxRange,
      samplePriceFrom,
      samplePriceTo,
      samplePriceMaxRange,
      gauge,
      weight,
      weightUnit,
      effectiveWidth,
      effectiveWidthUnit,
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      ...commonSearchProduct
    } = searchProduct || {};

    skuFilter = {
      bulkPriceFrom: commonSearchProduct?.['materialHasSku.skuList.bulkPriceFrom'],
      bulkPriceTo: commonSearchProduct?.['materialHasSku.skuList.bulkPriceTo'],
      bulkPriceMaxRange: commonSearchProduct?.['materialHasSku.skuList.bulkPriceMaxRange'],
      bulkPriceBelowMinimumFrom,
      bulkPriceBelowMinimumTo,
      bulkPriceBelowMinimumMaxRange,
      inventoryQuantityFrom,
      inventoryQuantityTo,
      inventoryQuantityMaxRange,
      samplePriceFrom,
      samplePriceTo,
      samplePriceMaxRange
    };

    showroomMaterialFilter = {
      scheduledLeadTime: commonSearchProduct?.scheduledLeadTime,
      scheduledLeadTimeSpecialOrder: commonSearchProduct?.scheduledLeadTimeSpecialOrder,
      minimumOrderLotForTotalFrom: commonSearchProduct?.minimumOrderLotForTotalFrom,
      minimumOrderLotForTotalTo: commonSearchProduct?.minimumOrderLotForTotalTo,
      minimumOrderLotForTotalMaxRange: commonSearchProduct?.minimumOrderLotForTotalMaxRange,
      minimumOrderLotForColorFrom: commonSearchProduct?.minimumOrderLotForColorFrom,
      minimumOrderLotForColorTo: commonSearchProduct?.minimumOrderLotForColorTo,
      minimumOrderLotForColorMaxRange: commonSearchProduct?.minimumOrderLotForColorMaxRange
    };

    commonSearch['materialType1'] = {
      ...commonSearch?.['materialType1'],
      weight,
      mTenantUnitIdForWeight: weightUnit,
      gauge,
      effectiveWidth,
      mTenantUnitIdForEffectiveWidth: effectiveWidthUnit
    };

    commonSearch['materialType2'] = {
      ...commonSearch?.['materialType2'],
      weight,
      mTenantUnitIdForWeight: weightUnit,
      effectiveWidth,
      mTenantUnitIdForEffectiveWidth: effectiveWidthUnit
    };

    commonSearch['materialType4'] = {
      ...commonSearch?.['materialType4'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType5'] = {
      ...commonSearch?.['materialType5'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType6'] = {
      ...commonSearch?.['materialType6'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType7'] = {
      ...commonSearch?.['materialType7'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType8'] = {
      ...commonSearch?.['materialType8'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType9'] = {
      ...commonSearch?.['materialType9'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType10'] = {
      ...commonSearch?.['materialType10'],
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    commonSearch['materialType11'] = {
      ...commonSearch?.['materialType11'],
      weight,
      mTenantUnitIdForWeight: weightUnit,
      gauge,
      effectiveWidth,
      mTenantUnitIdForEffectiveWidth: effectiveWidthUnit,
      minimumSize,
      maximumSize,
      maximumSizeMaxRange,
      mTenantUnitIdForSize,
      'sizeStrings.mSystemSizeStringValueFrom': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'],
      'sizeStrings.mSystemSizeStringValueTo': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'],
      'sizeStrings.mSystemSizeStringValueMaxRange': commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange']
    };

    if (colors && colors.length) {
      skuFilter = {
        ...skuFilter,
        colors
      };
    }

    delete commonSearchProduct?.['materialHasSku.skuList.bulkPriceFrom'];
    delete commonSearchProduct?.['materialHasSku.skuList.bulkPriceTo'];
    delete commonSearchProduct?.['materialHasSku.skuList.bulkPriceMaxRange'];
    delete commonSearchProduct?.['sizeStrings.mSystemSizeStringValueFrom'];
    delete commonSearchProduct?.['sizeStrings.mSystemSizeStringValueTo'];
    delete commonSearchProduct?.['sizeStrings.mSystemSizeStringValueMaxRange'];

    // end convert sku and showroom filter

    if (commonSearchProduct && Object.keys(commonSearchProduct).length) {
      const mustQueryProduct = this.queryObjectConvert(commonSearchProduct, seriesList, skuIds);
      query['must'].push(...mustQueryProduct);
    }

    if (commonSearch && Object.keys(commonSearch).length) {
      const shouldQueryCommon = this.listTabsQueryConvert(commonSearch);
      if (shouldQueryCommon.length) {
        query['must'].push({
          bool: {
            should: shouldQueryCommon
          }
        });
      }
    }

    if (tags) {
      const queryTags = this.shouldQueryTagConvert(tags);
      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }

    if (!ignoreCheckOrg) {
      const authQuery = this.handleAuthority(user);
      if (authQuery?.length) {
        query['must'].push({
          bool: {
            should: authQuery
          }
        });
      }
    }
    params = {
      limit: data?.limit,
      page: data?.page,
      query: {
        bool: {
          ...query
        }
      },
      selectedFields: [
        'id',
        'tenantId',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'mTenantMaterialCategory1',
        'mTenantMaterialCategory2',
        'mTenantMaterialCategory3',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason',
        'personInCharge',
        'mTenantUnitForSalesAndPurchasesUnitPrice',
        'mTenantCurrency',
        'openDateForShowroom',
        'closeDateForShowroom',
        'isMaterialHasSku',
        'activeStatus'
      ]
    };

    const orderBy = data?.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    if (skuFilter) {
      params = { ...params, skuFilter };
    }

    if (showroomMaterialFilter) {
      params = { ...params, showroomMaterialFilter };
    }

    return params;
  }

  convertBuyerParamsOpenSearch(data: any) {
    const { condition, commonSetting, colorSetting, characteristicSetting } = data;

    let params = null;
    const query: any = { must: [] };

    if (condition && Object.keys(condition).length) {
      if (condition.keyword) {
        const queryKeyword = this.handKeyword(condition.keyword);
        if (queryKeyword.length) {
          query['must'].push({
            bool: {
              should: queryKeyword
            }
          });
        }
      }
    }

    if (characteristicSetting && Object.keys(characteristicSetting).length) {
      const queryTags: any = [];
      const tagType: any = {
        1: 'appearanceShapeSearchTags',
        2: 'dyeingMethodSearchTags',
        3: 'functionSearchTags',
        4: 'patternSearchTags',
        5: 'processingMethodSearchTags',
        6: 'recommendationSearchTags',
        7: 'suggestedItemsSearchTags',
        8: 'sustainableSearchTags',
        9: 'textureSearchTags',
        10: 'precautionsSearchTags'
      };

      Object.values(characteristicSetting).forEach(({ tagGroup, tags }: any) => {
        const q: any = {
          nested: {
            path: tagType[tagGroup.tagType],
            query: {
              bool: {
                should: []
              }
            },
            ignore_unmapped: true
          }
        };

        Object.keys(tags).forEach((key: any) => {
          q.nested.query.bool.should.push({
            match_phrase: {
              [`${tagType[tagGroup.tagType]}.mSystemTagId`]: key
            }
          });
        });

        queryTags.push(q);
      });

      if (queryTags.length) {
        query['must'].push({
          bool: {
            should: queryTags
          }
        });
      }
    }

    // Convert sku and showroom filter for RDB
    let skuFilter = {};
    let showroomMaterialFilter: any = {};
    const commonSearch: any = {};

    if (colorSetting && Object.keys(colorSetting).length) {
      skuFilter = {
        colors: Object.keys(colorSetting)
      };
    }

    if (commonSetting && Object.keys(commonSetting).length) {
      const convertCommonSetting = {
        'mTenantSeries.mTenantSeriesValue': commonSetting?.series ?? undefined,
        'mTenantSeason.mSystemSeasonId': commonSetting?.season ?? undefined,
        'mTenantCountryForShipping.mSystemCountryIdForShipping': commonSetting?.shippingCountry ?? undefined,
        'mTenantCountryForOrigin.mSystemCountryIdForOrigin': commonSetting?.originCountry ?? undefined,
        minimumOrderLotForTotalFrom: commonSetting?.orderLotQuantity?.from ?? undefined,
        minimumOrderLotForTotalTo: commonSetting?.orderLotQuantity?.to ?? undefined,
        minimumOrderLotForTotalMaxRange: commonSetting?.orderLotQuantity?.maxRange ?? undefined,
        'mTenantUnitForLotTotal.mSystemUnitIdForLotTotal': commonSetting?.orderLotQuantity?.unit ?? undefined,
        'mTenantUnitForLotColor.mSystemUnitIdForLotColor': commonSetting?.orderLotColor?.unit ?? undefined,
        minimumOrderLotForColorFrom: commonSetting?.orderLotColor?.from ?? undefined,
        minimumOrderLotForColorTo: commonSetting?.orderLotColor?.to ?? undefined,
        minimumOrderLotForColorMaxRange: commonSetting?.orderLotColor?.maxRange ?? undefined,
        'buyer.mTenantUnitForSalesAndPurchasesUnitPrice.mSystemUnitIdForSalesAndPurchasesUnitPrice': [
          commonSetting?.salePrice?.unit ?? undefined,
          commonSetting?.minPrice?.unit ?? undefined
        ].filter((item) => item),
        'mTenantUnitForSampleUnitPrice.mSystemUnitIdForSampleUnitPrice': commonSetting?.samplePrice?.unit ?? undefined,
        'mTenantUnitForInventory.mSystemUnitIdForInventory': commonSetting?.quantity?.unit ?? undefined,
        scheduledLeadTime: commonSetting?.estimateTime ?? undefined,
        scheduledLeadTimeSpecialOrder: commonSetting?.estimateTimeSpecialOrder ?? undefined,
        firstMaterialComposition: commonSetting?.composition1 ?? undefined,
        firstCompositionMixedRatio: commonSetting?.composition1Ratio ?? undefined,
        secondMaterialComposition: commonSetting?.composition2 ?? undefined,
        secondCompositionMixedRatio: commonSetting?.composition2Ratio ?? undefined
      };

      const mustQueryProduct = this.queryObjectConvert(convertCommonSetting);
      query['must'].push(...mustQueryProduct);

      skuFilter = {
        ...skuFilter,
        bulkPriceFrom: commonSetting?.salePrice?.from ?? undefined,
        bulkPriceTo: commonSetting?.salePrice?.to ?? undefined,
        bulkPriceMaxRange: commonSetting?.salePrice?.maxRange ?? undefined,
        bulkPriceBelowMinimumFrom: commonSetting?.minPrice?.from ?? undefined,
        bulkPriceBelowMinimumTo: commonSetting?.minPrice?.to ?? undefined,
        bulkPriceBelowMinimumMaxRange: commonSetting?.minPrice?.maxRange ?? undefined,
        inventoryQuantityFrom: commonSetting?.quantity?.from ?? undefined,
        inventoryQuantityTo: commonSetting?.quantity?.to ?? undefined,
        inventoryQuantityMaxRange: commonSetting?.quantity?.maxRange ?? undefined,
        samplePriceFrom: commonSetting?.samplePrice?.from ?? undefined,
        samplePriceTo: commonSetting?.samplePrice?.to ?? undefined,
        samplePriceMaxRange: commonSetting?.samplePrice?.maxRange ?? undefined
      };

      showroomMaterialFilter = {
        scheduledLeadTime: convertCommonSetting?.scheduledLeadTime,
        scheduledLeadTimeSpecialOrder: convertCommonSetting?.scheduledLeadTimeSpecialOrder,
        minimumOrderLotForTotalFrom: convertCommonSetting?.minimumOrderLotForTotalFrom,
        minimumOrderLotForTotalTo: convertCommonSetting?.minimumOrderLotForTotalTo,
        minimumOrderLotForTotalMaxRange: convertCommonSetting?.minimumOrderLotForTotalMaxRange,
        minimumOrderLotForColorFrom: convertCommonSetting?.minimumOrderLotForColorFrom,
        minimumOrderLotForColorTo: convertCommonSetting?.minimumOrderLotForColorTo,
        minimumOrderLotForColorMaxRange: convertCommonSetting?.minimumOrderLotForColorMaxRange,
        mTenantUnitIdForSalesAndPurchasesUnitPrice: commonSetting?.salePrice?.unit ?? undefined,
        mTenantUnitIdForBelowMinimumUnitPrice: commonSetting?.minPrice?.unit ?? undefined,
        mTenantUnitIdForSampleUnitPrice: commonSetting?.samplePrice?.unit ?? undefined,
        mTenantUnitIdForInventory: commonSetting?.quantity?.unit ?? undefined,
        mTenantUnitIdForLotTotal: commonSetting?.orderLotQuantity?.unit ?? undefined,
        mTenantUnitIdForLotColor: commonSetting?.orderLotColor?.unit ?? undefined
      };

      commonSearch['materialType1'] = {
        ...commonSearch?.['materialType1'],
        weight: commonSetting?.weight ?? undefined,
        mTenantUnitIdForWeight: commonSetting?.weightUnitId ?? undefined,
        gauge: commonSetting?.gauge ?? undefined,
        effectiveWidth: commonSetting?.effectiveWidth ?? undefined,
        mTenantUnitIdForEffectiveWidth: commonSetting?.effectiveWidthUnitId ?? undefined
      };

      commonSearch['materialType2'] = {
        ...commonSearch?.['materialType2'],
        weight: commonSetting?.weight ?? undefined,
        mTenantUnitIdForWeight: commonSetting?.weightUnitId ?? undefined,
        effectiveWidth: commonSetting?.effectiveWidth ?? undefined,
        mTenantUnitIdForEffectiveWidth: commonSetting?.effectiveWidthUnitId ?? undefined
      };

      commonSearch['materialType4'] = {
        ...commonSearch?.['materialType4'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType5'] = {
        ...commonSearch?.['materialType5'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType6'] = {
        ...commonSearch?.['materialType6'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType7'] = {
        ...commonSearch?.['materialType7'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType8'] = {
        ...commonSearch?.['materialType8'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType9'] = {
        ...commonSearch?.['materialType9'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType10'] = {
        ...commonSearch?.['materialType10'],
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      commonSearch['materialType11'] = {
        ...commonSearch?.['materialType11'],
        weight: commonSetting?.weight ?? undefined,
        mTenantUnitIdForWeight: commonSetting?.weightUnitId ?? undefined,
        gauge: commonSetting?.gauge ?? undefined,
        effectiveWidth: commonSetting?.effectiveWidth ?? undefined,
        mTenantUnitIdForEffectiveWidth: commonSetting?.effectiveWidthUnitId ?? undefined,
        minimumSize: commonSetting?.sizeNumber?.from ?? undefined,
        maximumSize: commonSetting?.sizeNumber?.to ?? undefined,
        maximumSizeMaxRange: commonSetting?.sizeNumber?.maxRange ?? undefined,
        'mTenantUnitForSize.mSystemUnitIdForSize': commonSetting?.sizeNumber?.unit ?? undefined,
        'sizeStrings.mSystemSizeStringValueFrom': commonSetting?.sizeString?.from ?? undefined,
        'sizeStrings.mSystemSizeStringValueTo': commonSetting?.sizeString?.to ?? undefined
      };

      if (commonSearch && Object.keys(commonSearch).length) {
        const shouldQueryCommon = this.listTabsQueryConvert(commonSearch);
        if (shouldQueryCommon.length) {
          query['must'].push({
            bool: {
              should: shouldQueryCommon
            }
          });
        }
      }
    }

    params = {
      limit: data.limit,
      page: data.page,
      query: {
        bool: {
          ...query
        }
      },
      selectedFields: [
        'id',
        'materialCode',
        'materialNo',
        'name',
        'updatedAt',
        'mTenantManagementClassification',
        'locationBig',
        'locationSmall',
        'locationOrigin',
        'materialImagesAndVideos',
        'mSystemMaterialCategoryGroupType',
        'mTenantSeason',
        'mTenantMaterialCategory1',
        'mTenantMaterialCategory2',
        'mTenantMaterialCategory3',
        'mSystemMaterialCategory1',
        'mSystemMaterialCategory2',
        'mSystemMaterialCategory3',
        'mTenantCurrency'
      ]
    };

    const mustQueryCondition = this.queryObjectConvert(condition);
    query['must'].push(...mustQueryCondition);

    const orderBy = data.condition?.orderBy ?? null;

    if (orderBy) {
      params = { ...params, sort: orderBy };
    }

    if (skuFilter) {
      params = { ...params, skuFilter };
    }

    if (showroomMaterialFilter) {
      params = { ...params, showroomMaterialFilter };
    }

    return params;
  }

  handleAuthority(user: any, listOrg?: any[]) {
    const tenant: any = user?.tenants?.find((x: any) => x.id == this.tokenService.getTenant());
    let authorityGroups = tenant?.authorityGroups;
    const isSystemAdmin = user?.isSystemAdmin;

    const role = tenant?.role;
    const result: any[] = [];

    const key1 = 'mTenantAuthorityGroup1.mTenantAuthorityGroupId1';
    const key2 = 'mTenantAuthorityGroup2.mTenantAuthorityGroupId2';
    const key3 = 'mTenantAuthorityGroup3.mTenantAuthorityGroupId3';
    if (isSystemAdmin || role === Role.MANAGER) {
      if (listOrg && listOrg.length) {
        for (let i = 0; i < listOrg.length; i++) {
          const authGroup = listOrg[i];
          result.push({
            match_phrase: {
              [key1]: authGroup
            }
          });
          result.push({
            match_phrase: {
              [key2]: authGroup
            }
          });
          result.push({
            match_phrase: {
              [key3]: authGroup
            }
          });
        }
        return result;
      }
      return null;
    }
    if (role === Role.NORMAL) {
      if (listOrg && listOrg.length) authorityGroups = authorityGroups.filter((ele: any) => listOrg?.includes(ele.id));
      for (let i = 0; i < authorityGroups.length; i++) {
        const authGroup = authorityGroups[i];
        result.push({
          match_phrase: {
            [key1]: authGroup.id
          }
        });
        result.push({
          match_phrase: {
            [key2]: authGroup.id
          }
        });
        result.push({
          match_phrase: {
            [key3]: authGroup.id
          }
        });
      }
    }
    return result;
  }

  handleSeries(seriesList: any, data: any) {
    const resultQuery: any[] = [];

    const lstSeriesData = seriesList.filter((item: any) => item.name === data);

    for (let i = 0; i < lstSeriesData.length; i++) {
      const el = lstSeriesData[i];
      if (el.id) {
        resultQuery.push({
          match_phrase: {
            'mTenantSeries.mTenantSeriesId': el.id
          }
        });
      }
    }

    return resultQuery;
  }

  handleFiberFineness(data: any, line: number) {
    const valueFrom = line == 1 ? 'firstFiberFinenessFrom' : 'secondFiberFinenessFrom';
    const valueTo = line == 1 ? 'firstFiberFinenessTo' : 'secondFiberFinenessTo';

    const fiberFinenessType =
      line == 1 ? 'mSystemFiberFinenessTypeFor1stFiberFineness' : 'mSystemFiberFinenessTypeFor2ndFiberFineness';

    const fiberFinenessTypeD = line == 1 ? 'firstFiberFinenessTypeD' : 'secondFiberFinenessTypeD';
    const fiberFinenessYarnCountFrom =
      line == 1 ? 'firstFiberFinenessYarnCountFrom' : 'secondFiberFinenessYarnCountFrom';
    const fiberFinenessYarnCountTo = line == 1 ? 'firstFiberFinenessYarnCountTo' : 'secondFiberFinenessYarnCountTo';
    const fiberFinenessYarnCountValueFrom =
      line == 1 ? 'firstFiberFinenessYarnCountValueFrom' : 'secondFiberFinenessYarnCountValueFrom';
    const fiberFinenessYarnCountValueTo =
      line == 1 ? 'firstFiberFinenessYarnCountValueTo' : 'secondFiberFinenessYarnCountValueTo';
    const fiberFinenessDtexValueFrom =
      line == 1 ? 'firstFiberFinenessDtexValueFrom' : 'secondFiberFinenessDtexValueFrom';
    const fiberFinenessDtexValueTo = line == 1 ? 'firstFiberFinenessDtexValueTo' : 'secondFiberFinenessDtexValueTo';

    const type: any = data[fiberFinenessType];
    const fiberTypeD: any = data[fiberFinenessTypeD];
    const from = data[valueFrom];
    const to = data[valueTo];

    switch (type) {
      case 1:
      case '1':
      case 2:
      case '2':
        data[fiberFinenessYarnCountValueFrom] = from;
        data[fiberFinenessYarnCountValueTo] = to;
        data[fiberFinenessYarnCountFrom] = null;
        data[fiberFinenessYarnCountTo] = null;
        data[fiberFinenessDtexValueFrom] = null;
        data[fiberFinenessDtexValueTo] = null;
        break;
      case 3:
      case '3':
      case 4:
      case '4':
        if (fiberTypeD === 'D') {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = from;
          data[fiberFinenessYarnCountTo] = to;
          data[fiberFinenessDtexValueFrom] = null;
          data[fiberFinenessDtexValueTo] = null;
        } else if (fiberTypeD === 'dtex') {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = null;
          data[fiberFinenessYarnCountTo] = null;
          data[fiberFinenessDtexValueFrom] = from;
          data[fiberFinenessDtexValueTo] = to;
        } else {
          data[fiberFinenessYarnCountValueFrom] = null;
          data[fiberFinenessYarnCountValueTo] = null;
          data[fiberFinenessYarnCountFrom] = null;
          data[fiberFinenessYarnCountTo] = null;
          data[fiberFinenessDtexValueFrom] = null;
          data[fiberFinenessDtexValueTo] = null;
        }
        break;

      default:
        break;
    }

    return data;
  }

  inValidInputSearch(data: any) {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const dataObj = data[key];

      const objKeys = Object.keys(dataObj);
      for (let j = 0; j < objKeys.length; j++) {
        const childKey = objKeys[j];

        const indexFrom = childKey.length - 4;
        const isFromField = childKey.indexOf('From') === indexFrom;

        if (childKey.match(/From/) && isFromField) {
          const field = childKey.substring(0, indexFrom);
          const fieldTo = field + 'To';

          if (!field.match(/mSystemSizeStringValue/) && dataObj[fieldTo] && dataObj[childKey] > dataObj[fieldTo]) {
            return true;
          }
        }

        if (childKey.match(/minimumSize/)) {
          const fieldTo = 'maximumSize';

          if (+dataObj[fieldTo] && +dataObj[childKey] > +dataObj[fieldTo]) {
            return true;
          }
        }

        if (childKey.match(/FiberFineness/)) {
          const firstValueFrom = dataObj['firstFiberFinenessFrom'];
          const firstValueTo = dataObj['firstFiberFinenessTo'];
          const firstType = dataObj['mSystemFiberFinenessTypeFor1stFiberFineness'];
          const firstDtex = dataObj['firstFiberFinenessTypeD'];
          const secondValueFrom = dataObj['secondFiberFinenessFrom'];
          const secondValueTo = dataObj['secondFiberFinenessTo'];
          const secondType = dataObj['mSystemFiberFinenessTypeFor2ndFiberFineness'];
          const secondDtex = dataObj['secondFiberFinenessTypeD'];

          const check1: boolean =
            (+firstValueFrom > 0 || +firstValueTo > 0) && (firstType == 3 || firstType == 4) && !firstDtex;
          const check2: boolean =
            (+secondValueFrom > 0 || +secondValueTo > 0) && (secondType == 3 || secondType == 4) && !secondDtex;

          if (check1 || check2) {
            return true;
          }
        }
      }
    }
    return false;
  }

  handKeyword(keyword: string) {
    const pattern = /\s+|　+/;
    const keys = keyword.split(pattern);

    const querys = [];

    for (let i = 0; i < keys.length; i++) {
      const item = keys[i];
      if (item) {
        querys.push({
          query_string: {
            default_field: 'keywords',
            query: `*${this.beautyKeywordText(item)}*`
          }
        });
      }
    }

    return querys;
  }

  querySKUValidFrom(data: any, product: any, colors: any) {
    const result: any = [];
    const queryPriceSku = this.queryPriceSku(product);
    const queryColor = this.queryColor(colors);

    for (let i = 0; i < data.length; i++) {
      const queryHasSku: any = { must: [] };
      const skuId = data[i];

      const attributeQuery = {
        match_phrase: {
          'materialHasSku.id': skuId
        }
      };

      if (attributeQuery) {
        queryHasSku['must'].push(attributeQuery);
      }

      if (queryPriceSku.length) {
        queryHasSku['must'].push(...queryPriceSku);
      }

      if (queryColor) {
        queryHasSku['must'].push(queryColor);
      }

      if (queryHasSku['must'].length) {
        result.push({
          bool: { ...queryHasSku }
        });
      }
    }

    return result;
  }

  queryColor(colors: any) {
    let query = null;
    const queryColor = this.shouldQueryColorConvert(colors);
    if (queryColor?.length) {
      query = {
        nested: {
          path: 'materialHasSku.skuList',
          query: {
            bool: {
              should: queryColor
            }
          },
          ignore_unmapped: true
        }
      };
    }

    return query;
  }

  queryPriceSku(product: any) {
    const result: any = [];
    if (Object.keys(product)?.length) {
      for (const key in product) {
        if (!key.match(/MaxRange/) && (key.match(/(bulkPrice)/) || key.match(/(costOfGoodsPurchased)/))) {
          const query = 'range';
          const attributeQuery = this.keyQuery(key, product[key], query, '', product);
          attributeQuery !== null && result.push(attributeQuery);
        }
      }
    }

    return result;
  }
  // end open search

  getYouTubeVideoId(url: string) {
    const pattern =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/;

    const match = url.match(pattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  checkStringWidth(str = '') {
    if (!str) str = '';

    let len = 0;
    for (let i = 0; i < str.length; i++) {
      const code = str.charCodeAt(i);
      if ((code >= 0x0020 && code <= 0x1fff) || (code >= 0xff61 && code <= 0xff9f)) {
        len = 1;
      } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
        len = 2;
      } else {
        len = 0;
      }
    }

    return len;
  }

  containsFullwidth(text: string): boolean {
    const fullwidthPattern = /[\uFF00-\uFFEF]/;

    return fullwidthPattern.test(text);
  }

  containsFullwidthWhitespace(text: string): boolean {
    const fullwidthWhitespacePattern = /[　]/;

    return fullwidthWhitespacePattern.test(text);
  }

  isHaftWidth(str = '') {
    return this.checkStringWidth(str) === 1;
  }

  isFullWidth(str = '') {
    return this.containsFullwidth(str) || this.containsFullwidthWhitespace(str);
    // return this.checkStringWidth(str) === 2;
  }

  // localStorage
  setStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getStorage(key: string) {
    const val = localStorage.getItem(key);
    return val;
  }

  setStoragePaging(screen: string, data: any) {
    const dataStorage = JSON.stringify(data);
    const currentUser = JSON.parse(this.tokenService.getUser());
    localStorage.setItem(`storagePaging_${screen}_${this.tokenService.getTenant()}_${currentUser?.id}`, dataStorage);
  }

  getStoragePaging(screen: string) {
    const currentUser = JSON.parse(this.tokenService.getUser());
    const val = localStorage.getItem(`storagePaging_${screen}_${this.tokenService.getTenant()}_${currentUser?.id}`);
    return val ? JSON.parse(val) : null;
  }

  setStorageSearchOrder(screen: string, data: any) {
    const getUser = JSON.parse(this.tokenService.getUser());

    const dataStorage = JSON.stringify(data);
    localStorage.setItem(`${screen}_${this.tokenService.getTenant()}_${getUser?.id}`, dataStorage);
  }

  getStorageSearchOrder(screen: string) {
    const getUser = JSON.parse(this.tokenService.getUser());

    const val = localStorage.getItem(`${screen}_${this.tokenService.getTenant()}_${getUser?.id}`);
    return val ? JSON.parse(val) : null;
  }

  removeStorage(key: string) {
    localStorage.removeItem(key);
  }
  // end localStorage
  scrollToItem(id: string, time = 250) {
    try {
      if (this.callbackFunc) clearTimeout(this.callbackFunc);
      this.callbackFunc = setTimeout(() => {
        const elmt = document.querySelector(`#${id}`);
        elmt?.scrollIntoView();
      }, time);
    } catch (error) {
      console.error(`error ${error}`);
    }
  }

  validateFileType(file: any, allowMimes: string[] = FILE_TYPE) {
    const extension = file.name.split('.').pop();

    if (extension?.toLowerCase() === 'jfif') {
      return false;
    }

    if (extension?.toLowerCase() === 'mov') {
      return true;
    }

    let fileType = '';
    if (file.type) {
      fileType = file.type.split('/')[1];
    } else {
      fileType = file.name.split('.')[1];
    }

    return allowMimes.some((type) => fileType.includes(type));
  }

  validateStringLength(str: string, maxHalfWidth: number, maxFullWidth: number) {
    let halfWidthCount = 0;
    let fullWidthCount = 0;

    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);

      // Kiểm tra ký tự half-width
      if (
        (charCode >= 0x0020 && charCode <= 0x007e) || // Ký tự ASCII
        (charCode >= 0xff61 && charCode <= 0xff9f) // Ký tự half-width tiếng Nhật
      ) {
        halfWidthCount++;
      }
      // Kiểm tra ký tự full-width
      else if (
        (charCode >= 0x3000 && charCode <= 0x30ff) || // Ký tự full-width tiếng Nhật
        (charCode >= 0xff00 && charCode <= 0xffef) // Ký tự full-width khác
      ) {
        fullWidthCount++;
      }
    }

    return halfWidthCount <= maxHalfWidth && fullWidthCount <= maxFullWidth;
  }

  validateSurnameRomanji(input: string) {
    if (!input) return false;
    const romanAlphabetRegex = /^[a-zA-Z0-9.\-,;:]+$/;
    return romanAlphabetRegex.test(input);
  }

  randomAlphaNumeric(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  convertDataAutoComplete(data: any[]) {
    const emptyItem: any = {
      id: null,
      name: ''
    };
    const results = data.map((ele) => ({
      ...ele,
      id: ele.id,
      name: ele.name
    }));
    results.unshift(emptyItem);
    return results;
  }

  beautyKeywordText(keyword: string) {
    if (!keyword) return '';
    let result = keyword.toLowerCase();

    result = result.replace(/[\uFF01-\uFF5E]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
    });

    const kanaMap: any = {
      ｱ: 'ア',
      ｲ: 'イ',
      ｳ: 'ウ',
      ｴ: 'エ',
      ｵ: 'オ',
      ｶ: 'カ',
      ｷ: 'キ',
      ｸ: 'ク',
      ｹ: 'ケ',
      ｺ: 'コ',
      ｻ: 'サ',
      ｼ: 'シ',
      ｽ: 'ス',
      ｾ: 'セ',
      ｿ: 'ソ',
      ﾀ: 'タ',
      ﾁ: 'チ',
      ﾂ: 'ツ',
      ﾃ: 'テ',
      ﾄ: 'ト',
      ﾅ: 'ナ',
      ﾆ: 'ニ',
      ﾇ: 'ヌ',
      ﾈ: 'ネ',
      ﾉ: 'ノ',
      ﾊ: 'ハ',
      ﾋ: 'ヒ',
      ﾌ: 'フ',
      ﾍ: 'ヘ',
      ﾎ: 'ホ',
      ﾏ: 'マ',
      ﾐ: 'ミ',
      ﾑ: 'ム',
      ﾒ: 'メ',
      ﾓ: 'モ',
      ﾔ: 'ヤ',
      ﾕ: 'ユ',
      ﾖ: 'ヨ',
      ﾗ: 'ラ',
      ﾘ: 'リ',
      ﾙ: 'ル',
      ﾚ: 'レ',
      ﾛ: 'ロ',
      ﾜ: 'ワ',
      ｦ: 'ヲ',
      ﾝ: 'ン',
      ｧ: 'ァ',
      ｨ: 'ィ',
      ｩ: 'ゥ',
      ｪ: 'ェ',
      ｫ: 'ォ',
      ｬ: 'ャ',
      ｭ: 'ュ',
      ｮ: 'ョ',
      ｯ: 'ッ',
      ｳﾞ: 'ヴ',
      ｶﾞ: 'ガ',
      ｷﾞ: 'ギ',
      ｸﾞ: 'グ',
      ｹﾞ: 'ゲ',
      ｺﾞ: 'ゴ',
      ｻﾞ: 'ザ',
      ｼﾞ: 'ジ',
      ｽﾞ: 'ズ',
      ｾﾞ: 'ゼ',
      ｿﾞ: 'ゾ',
      ﾀﾞ: 'ダ',
      ﾁﾞ: 'ヂ',
      ﾂﾞ: 'ヅ',
      ﾃﾞ: 'デ',
      ﾄﾞ: 'ド',
      ﾊﾞ: 'バ',
      ﾋﾞ: 'ビ',
      ﾌﾞ: 'ブ',
      ﾍﾞ: 'ベ',
      ﾎﾞ: 'ボ',
      ﾊﾟ: 'パ',
      ﾋﾟ: 'ピ',
      ﾌﾟ: 'プ',
      ﾍﾟ: 'ペ',
      ﾎﾟ: 'ポ'
    };

    result = result.replace(/([ｱ-ﾝｧ-ｮ])ﾞ/g, function (match, char) {
      return kanaMap[char + 'ﾞ'] || char;
    });
    result = result.replace(/([ｱ-ﾝｧ-ｮ])ﾟ/g, function (match, char) {
      return kanaMap[char + 'ﾟ'] || char;
    });

    result = result.replace(/[ｱ-ﾝｧ-ｮ]/g, function (char) {
      return kanaMap[char] || char;
    });

    return result;
  }

  isEqual(value: any, other: any): boolean {
    // Check for reference equality
    if (value === other) return true;

    // Check for non-object types (including null)
    if (typeof value !== 'object' || typeof other !== 'object' || value === null || other === null) {
      return false;
    }

    // Compare arrays
    if (Array.isArray(value) && Array.isArray(other)) {
      if (value.length !== other.length) return false;
      return value.every((v, i) => this.isEqual(v, other[i]));
    }

    // Compare objects
    const keysA = Object.keys(value);
    const keysB = Object.keys(other);
    if (keysA.length !== keysB.length) return false;

    return keysA.every((key) => keysB.includes(key) && this.isEqual(value[key], other[key]));
  }

  sortBase(data: [], key: string, order: string) {
    return data.sort((a: any, b: any) => {
      if (order === 'asc') {
        if (a[key] === null || a[key] === '') return 1;
        if (b[key] === null || b[key] === '') return -1;

        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
          return a[key]?.localeCompare(b[key]);
        } else {
          return a[key] - b[key];
        }
      } else {
        if (a[key] === null || a[key] === '') return -1;
        if (b[key] === null || b[key] === '') return 1;

        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
          return b[key]?.localeCompare(a[key]);
        } else {
          return b[key] - a[key];
        }
      }
    });
  }

  multiSort(data: [], sortFields: string[], order: string) {
    return data.sort((a, b) => {
      for (const field of sortFields) {
        const valueA: any = a?.[field];
        const valueB: any = b?.[field];

        const isEmptyA = valueA === null || valueA === '' || valueA === undefined;
        const isEmptyB = valueB === null || valueB === '' || valueA === undefined;

        if (isEmptyA && !isEmptyB) return order === 'asc' ? -1 : 1;
        if (!isEmptyA && isEmptyB) return order === 'asc' ? 1 : -1;

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          const compareResult = valueA.localeCompare(valueB);
          if (compareResult !== 0) return order === 'asc' ? compareResult : -compareResult;
        } else {
          if (valueA < valueB) return order === 'asc' ? -1 : 1;
          if (valueA > valueB) return order === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  }

  isUUID(value: string) {
    if (value === null || value === undefined) return false;

    return value?.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
  }

  searchTenantSizeValue(name: string) {
    const localTenantSizeStrings = JSON.parse(localStorage.getItem('tenantSizeStrings') || '[]');

    let listSizeStrings = this.tenantService.tenantSizeStrings;
    if (!listSizeStrings?.length) {
      listSizeStrings = localTenantSizeStrings;
    }

    const result = listSizeStrings.find((item: any) => (this.isUUID(name) ? item.id === name : item.name === name));
    return result?.value;
  }

  isNumber(value: any) {
    return !value || isNaN(Number(value)) ? false : true;
  }

  escapeSql(value: any) {
    if (typeof value !== 'string') return value;
    return value.replace(/\\/g, '\\\\').replace(/'/g, "''");
  }
}
