import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  Query,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPrefix } from '@angular/material/form-field';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { combineLatestWith, map, startWith, take, tap } from 'rxjs/operators';
import { MyErrorStateMatcher } from 'src/app/shared/state-matcher/error-state-matcher';
import { APP_INPUT_ERROR, ErrorComponent } from '../../error/error.component';

export interface DataList {
  id: number | string | null;
  name: string;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true
    }
  ]
})
export class AutoCompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  autocompleteInput = new FormControl<string | { value: string; forceUpdateInputValue?: boolean } | null>(null);
  filteredOptions!: Observable<any[]>;
  validateOptionObs = new Subject<boolean>();
  autocompleteOptionChange!: Observable<any>;
  optionObs = new BehaviorSubject<any[]>([]);
  labelSelected = '';
  @Input() set options(data: any[]) {
    this.optionObs.next(data);
  }
  @Input() valueKey: string = 'id';
  @Input() labelKey: string = 'name';
  @Input() clearable = true;
  @Input() className? = 'w-[150px]';
  @Input() inputClass? = '';
  @Input() panelWidth: number | string;
  @Input() disabled: boolean = false;
  @Input() name? = '';
  @Input() labelInput?: string = '';
  @Input() readAsLabel: string | any = '';
  @Input() labelTemplate!: TemplateRef<any>;
  @Input() set validateOption(data: boolean) {
    setTimeout(() => {
      if (data === true) {
        this.validateOptionObs.next(data === true);
      }
    }, 0);
  }
  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngModelChange1: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatAutocomplete) _auto!: MatAutocomplete;
  @ContentChildren(APP_INPUT_ERROR, { descendants: true }) _errorChildren!: QueryList<ErrorComponent>;
  @ContentChild(MatPrefix, { descendants: true }) _inputPrefixChild!: Query;

  selected: any;
  optionsMap: Map<any, any> = new Map();

  _control!: NgControl;
  matcher!: MyErrorStateMatcher;

  onChange: any = (value: any) => {};
  onTouch: any = () => {};

  constructor(private injector: Injector) {}

  private _getInputValue(value: string | number | { value: string } | null): any {
    let newValue: any = '';
    switch (typeof value) {
      case 'object':
        newValue = value?.value ?? '';
        break;
      default:
        newValue = value;
        break;
    }
    return newValue;
  }

  ngOnInit(): void {
    //reset value to null if value is not exist in options (run only one time)
    this.validateOptionObs
      .pipe(combineLatestWith(this.optionObs), take(1))
      .subscribe(([validateDataSelected, options]) => {
        if (validateDataSelected) {
          const option = options?.find((value) => {
            const key = (this.valueKey && value[this.valueKey]) ?? value;
            return key === this.selected;
          });
          if (option === null || option === undefined) {
            this.selected = null;
            this.onChange(null);
            this.autocompleteInput.setValue({
              value: '',
              forceUpdateInputValue: true
            });
          }
        }
      });

    if (this.disabled) {
      this.autocompleteInput.disable({ onlySelf: true, emitEvent: false });
    }
    this._control = this.injector.get(NgControl);
    this.matcher = new MyErrorStateMatcher(this._control);
    this.filteredOptions = this.autocompleteInput.valueChanges.pipe(
      startWith(''),
      combineLatestWith(
        this.optionObs.pipe(
          tap((data: any[]) => {
            this.optionsMap.clear();
            for (const opts of data) {
              this.optionsMap.set(opts.id, opts);
            }
          })
        )
      ),
      map(([search, options]) => {
        return this._filter(this._getInputValue(search), options, typeof search === 'object') ?? [];
      })
    );
  }

  private _filter(search: string, options: any[], searchById: boolean): string[] {
    if (search === null || search === undefined || search === '') {
      return options;
    }

    const filterValue = `${search}`.trim().toLowerCase();
    const data = options.filter((option) => {
      const label = (this.labelKey && option[this.labelKey]) ?? option;
      const key = (this.valueKey && option[this.valueKey]) ?? option;
      if (searchById) {
        return (key === null || key === undefined ? '' : `${key}`) === `${search}`;
      }
      return (label === null || label === undefined ? '' : `${label}`).toLowerCase().includes(filterValue);
    });
    return data;
  }

  displayWith(): ((value: any) => string) | null {
    return (value: any) => {
      const item = this._auto.options.find((item) => item.value && item.value.value === this._getInputValue(value));
      this.labelSelected = item?.viewValue ?? '';
      if (item) return item.viewValue || '';
      return '';
    };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(val: string) {
    this.selected = val;
    this.autocompleteInput.setValue({
      value: this.selected,
      forceUpdateInputValue: true
    });
  }

  optionSelected(e: MatAutocompleteSelectedEvent) {
    const { option } = e;
    this.onModelChange(option.value);
  }

  onModelChange(data: any) {
    const value = data === null || data === undefined ? null : data.value;
    const previousValue = this.selected;
    this.selected = value;
    this.onChange(value);
    this.ngModelChange1.emit(value);
    this.onValueChange.emit({
      value: value,
      option: this.optionsMap.get(value) || undefined,
      previousValue,
      previousOption: previousValue ? this.optionsMap.get(previousValue) : undefined
    });
  }

  onHandleClear(event: Event) {
    if (!this.clearable) return;
    event.preventDefault();
    event.stopPropagation();
    this.autocompleteInput.setValue('');
    this.onModelChange(null);
  }

  flattenValue(option: any) {
    return option.toString();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.disabled) {
      this.autocompleteInput.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.autocompleteInput.enable({ onlySelf: true, emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    if (!this._auto || !this._auto.options) {
      return;
    }
    if (this._auto.options.length > 0) {
      this.autocompleteOptionChange = this._auto.options.changes.pipe(startWith(null));
    } else {
      this.autocompleteOptionChange = this._auto.options.changes;
    }
    this.autocompleteOptionChange
      .pipe(combineLatestWith(this.autocompleteInput.valueChanges.pipe(startWith(null))))
      .subscribe(() => {
        setTimeout(() => {
          const option = this._auto.options.find(({ value }) => value.value === this.selected);
          if (option) {
            option.select(false);
          }

          if (this.autocompleteInput.value) {
            switch (typeof this.autocompleteInput.value) {
              case 'object':
                if (this.autocompleteInput.value.forceUpdateInputValue) {
                  this.autocompleteInput.setValue({ value: this.selected });
                }
                break;
            }
          }
          //clear selected
          if (this.selected === null || this.selected === '' || this.selected === undefined) {
            for (let index = 0; index < this._auto.options.length; index++) {
              const op = this._auto.options.get(index);
              if (op && op.selected) {
                op.deselect(false);
              }
            }
          }
        }, 0);
      });
  }
}
