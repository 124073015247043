import { Component, HostListener, OnInit } from '@angular/core';
import { CheckPermissionService } from './core/services/check-permission.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { EditStatusService } from './core/services/edit-status.service';
import { environment } from 'src/environments/environment';
import { TokenService } from './core/services/token.service';
import { Router } from '@angular/router';
import { TranslateHelper } from './core/helpers/translate.helper';
import { NotifyService } from './shared/components/notify/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { ROUTES_EXCLUDED_FROM_TOKEN_EXPIRY } from './app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'srm';

  constructor(
    public checkPermissionService: CheckPermissionService,
    public permissionsService: NgxPermissionsService,
    public translate: TranslateService,
    private translateHelper: TranslateHelper,
    private notifyService: NotifyService,
    private editStatusService: EditStatusService,
    private tokenService: TokenService,
    private dialogRef: MatDialog,
    private router: Router
  ) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:keydown', ['$event'])
  checkTokenExpire(): void {
    const currentUrl = this.router.url;

    const isAllowedRoute = ROUTES_EXCLUDED_FROM_TOKEN_EXPIRY.some((route) => currentUrl.includes(route));

    if (isAllowedRoute) return;

    const isTokenExpired = this.tokenService.isTokenExpired();

    if (isTokenExpired) {
      this.dialogRef.closeAll();
      this.notifyService.error(this.translateHelper.__m('ERR-COM-0030'));

      setTimeout(() => {
        this.tokenService.removeToken();
        location.href = '/auth/login';
      }, 2000);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.editStatusService.getEditingStatus()) {
      $event.preventDefault();
    }
  }

  ngOnInit(): void {
    // Init i18n
    this.translate.addLangs(['en', 'ja']);
    this.translate.setDefaultLang('ja');
    this.translate.use('ja');

    const domain = document.location.hostname;

    const oldSrmDomain = `srm.${environment.rootDomain}`;

    const oldPlmDomain = `plm.${environment.rootDomain}`;

    if (domain == oldSrmDomain || domain == oldPlmDomain) {
      window.location.href = environment.buyerDomain;
    }

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang?.match(/en|ja/) ? browserLang : 'ja');
  }
}
