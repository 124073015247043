<mat-form-field
  [@.disabled]="true"
  class="flex-1 w-full"
  [ngClass]="{
    '!hidden': readAsLabel
  }"
  [class]="className ?? ''"
>
  @if (_inputPrefixChild) {
  <div
    matPrefix
    class="w-[15px]"
  >
    <ng-content select="[matPrefix]"></ng-content>
  </div>
  }

  <input
    type="text"
    [ngClass]="{ '!px-[10px]': labelTemplate }"
    matInput
    [placeholder]="labelInput ?? ''"
    [matAutocomplete]="auto"
    [formControl]="autocompleteInput"
    [errorStateMatcher]="matcher"
  />
  <div
    matSuffix
    class="w-[22px] cursor-pointer flex justify-start"
  >
    @if (selected === null || selected === undefined || selected === '' || !clearable || disabled) {
    <div class="pl-[1px]">
      <svg
        width="11"
        height="7"
        viewBox="0 0 11 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.69357 6.26073L0.959338 1.57205L1.63553 0.889295L5.68707 4.91464L9.69958 0.86314L10.3823 1.53933L5.69357 6.26073Z"
          fill="#515151"
        />
      </svg>
    </div>
    } @else if (!disabled && clearable) {
    <div
      (click)="onHandleClear($event)"
      class="cursor-pointer"
    >
      <mat-icon class="!w-[14px] !h-[14px] text-[14px] text-[#515151]">close</mat-icon>
    </div>
    }
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayWith()"
    (optionSelected)="optionSelected($event)"
  >
    @for (option of filteredOptions | async; track option) {
    <mat-option
      class="break-all"
      [value]="{ value: valueKey && (option[valueKey] ?? null) }"
      [disabled]="option?.disabled"
    >
      <ng-container
        *ngTemplateOutlet="labelTemplate ?? labelTemplateDefault; context: { option, labelKey }"
      ></ng-container>
    </mat-option>
    }
  </mat-autocomplete>
  @if (_errorChildren.length > 0) {
  <mat-error class="text-wrap break-words">
    <ng-content select="app-error"></ng-content>
  </mat-error>
  }
</mat-form-field>
<div
  class="text-over-line"
  [matTooltip]="labelSelected"
  matTooltipPosition="below"
  matTooltipClass="custom-tooltip"
  [ngClass]="{
    '!hidden': !readAsLabel
  }"
>
  {{ labelSelected }}
</div>

<ng-template
  #labelTemplateDefault
  let-option="option"
  let-labelKey="labelKey"
>
  <div
    [matTooltip]="labelKey && (option[labelKey] ?? option)"
    matTooltipClass="custom-tooltip"
  >
    {{ labelKey && (option[labelKey] ?? option) }}
  </div>
</ng-template>
