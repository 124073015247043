<div
  *ngIf="isLoading"
  class="fullscreen-loading"
>
  <mat-icon class="w-7 h-7">
    <mat-progress-spinner
      color="accent"
      diameter="24"
      mode="indeterminate"
    ></mat-progress-spinner>
  </mat-icon>
</div>
