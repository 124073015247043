import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input() appTooltip = '';
  private tooltipElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    this.createTooltip(event);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.destroyTooltip();
  }

  private createTooltip(event: MouseEvent) {
    if (this.appTooltip === '') return;

    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltipElement, this.renderer.createText(this.appTooltip));

    this.renderer.appendChild(document.body, this.tooltipElement);
    this.renderer.addClass(this.tooltipElement, 'tooltip-container');

    this.updateTooltipPosition(event);
  }

  private updateTooltipPosition(event: MouseEvent) {
    if (this.tooltipElement) {
      const offset = 5;
      const rect = this.el.nativeElement.getBoundingClientRect();

      const tooltipX = event.pageX;
      const tooltipY = rect.bottom + offset;

      this.renderer.setStyle(this.tooltipElement, 'left', `${tooltipX}px`);
      this.renderer.setStyle(this.tooltipElement, 'top', `${tooltipY}px`);
    }
  }

  private destroyTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null!;
    }
  }
}
