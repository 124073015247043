import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-detail-dialog',
  templateUrl: './notification-detail-dialog.component.html',
  styleUrls: ['./notification-detail-dialog.component.scss']
})
export class NotificationDetailDialogComponent {
  @ViewChild('contentContainer', { static: true }) contentContainer!: ElementRef;
  titleDialog = '';
  contentDialog = '';
  confirmButtonText = '';
  cancelButtonText = '';
  loading: boolean;

  // *** PLEASE NOT BINDING WIDTH, HEIGHT ***

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      textConfirm: string;
      textCancel: string;
      handleConfirm: () => void;
      hasLoading?: boolean;
    },
    private dialogRef: MatDialogRef<NotificationDetailDialogComponent>,
    private renderer: Renderer2
  ) {
    this.titleDialog = this.data.title ? this.data.title : '';
    this.contentDialog = this.data.content ? this.data.content : '';
    this.cancelButtonText = this.data.textCancel ? this.data.textCancel : 'confirmDialog.textCancel';
  }

  ngAfterViewInit() {
    this.contentContainer.nativeElement.innerHTML = this.contentDialog;

    const tooltipElements = this.contentContainer.nativeElement.querySelectorAll('.tooltip-target');
    tooltipElements.forEach((el: HTMLElement) => {
      const tooltipText = el.textContent;
      if (tooltipText) {
        this.createTooltip(el, tooltipText);
      }
    });
  }

  onConfirm(): void {
    if (this.loading) return;

    if (this.data.hasLoading) {
      this.loading = true;
    }

    this.data.handleConfirm();

    if (!this.data.hasLoading) {
      this.dialogRef.close(true);
    }
  }

  createTooltip(el: HTMLElement, data: string) {
    const tooltip = this.renderer.createElement('span');
    this.renderer.addClass(tooltip, 'custom-tooltip');
    this.renderer.setProperty(tooltip, 'innerText', data);
    this.renderer.appendChild(el, tooltip);

    this.renderer.listen(el, 'mouseenter', () => {
      this.renderer.setStyle(tooltip, 'display', 'block');
    });

    this.renderer.listen(el, 'mouseleave', () => {
      this.renderer.setStyle(tooltip, 'display', 'none');
    });
  }
}
