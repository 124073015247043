import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
  const originalConsoleLog = console.log;
  console.log = function (...args) {
    const allowedKeywords = ['[DEBUG]', '[INFO]', '[ERROR]'];
    if (args.some((arg) => typeof arg === 'string' && allowedKeywords.some((key) => arg.includes(key)))) {
      originalConsoleLog.apply(console, args);
    }
  };
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
