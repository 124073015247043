@if (!readAsLabel) {
<mat-form-field
  [@.disabled]="true"
  class="flex-1 w-full app-text-area-comp"
  [ngClass]="className"
>
  <textarea
    matInput
    [type]="type"
    appElementTracker
    [disableTracker]="!isApplyTrackerElement"
    class="form-input outline-none w-full py-5"
    rows="5"
    [ngClass]="{
      'form-input-label': readAsLabel,
      'form-input-border': isBorder,
    }"
    [readOnly]="readAsLabel"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    [placeholder]="placeHolder"
    (blur)="onHandleBlur($event)"
    (focus)="onHandleFocus($event)"
    [disabled]="isDisabled"
    [errorStateMatcher]="matcher"
  ></textarea>
  @if (_errorChildren.length > 0) {
  <mat-error class="text-wrap break-words">
    <ng-content select="app-error"></ng-content>
  </mat-error>
  }
</mat-form-field>
} @else {
<span
  class="px-[10px] text-over-line"
  [matTooltip]="value"
  matTooltipPosition="below"
  matTooltipClass="custom-tooltip"
  >{{ value }}</span
>
}
