import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, map, debounceTime, takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'app-search-comp',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppSearchComponent),
      multi: true
    }
  ]
})
export class AppSearchComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() disabled = false;
  @Input() className: string | any = '';
  @Input() placeHolder: string | any = '';

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInput: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('search', { static: true }) search!: ElementRef;

  onChange: any = () => {};
  onTouch: any = () => {};

  destroy$ = new Subject<void>();
  value = '';
  isEnterPressed = false;
  isDisabled = false;

  constructor() {}

  ngAfterViewInit() {
    this.isDisabled = this.disabled;
    fromEvent(this.search.nativeElement, 'input')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe((searchText) => {
        this.onInput.emit(searchText);
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(val: string) {
    this.value = val;
  }

  onModelChange(e: string) {
    this.value = e;
    this.onChange(e);
  }

  onHandleKeyPress(event: KeyboardEvent) {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      this.onEnter.emit(this.value);
    }
  }

  onHandleClear(event: Event) {
    this.onModelChange('');
    this.onClear.emit();
  }

  // stop blur event when click clear button
  onClickClearButton(event: Event) {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onHandleBlur(event: any) {
    this.blur.emit(event);
  }
}
