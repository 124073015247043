@if (!readAsLabel) {
<div
  class="auto-complete"
  #autoInput
  [ngClass]="{
    disable: isDisable
  }"
>
  <mat-form-field
    [class]="className"
    class="no-validate"
  >
    <input
      class="input-auto form-control bg-white border-b-gray-400 p-0 w-full"
      [placeholder]="labelInput ?? ''"
      type="text"
      [formControl]="dataCtrl"
      [matAutocomplete]="auto"
      (blur)="onBlur()"
      (focus)="openAutocomplete()"
      autocomplete="off"
      matInput
    />
    <label class="closeIcon">
      <mat-icon
        matSuffix
        class="text-[20px]"
        *ngIf="dataCtrl.value"
        (click)="onHandleClear()"
        >close</mat-icon
      >
      <div
        class="arrow-down"
        *ngIf="!dataCtrl.value"
      >
        <div class="arrow-down-icon"></div>
      </div>
    </label>
  </mat-form-field>
  <mat-autocomplete
    class="display-list"
    [panelWidth]="panelWidth"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)"
    hideSingleSelectionIndicator
  >
    <mat-option
      class="option-custom"
      *ngFor="let item of dataFiltered | async"
      [value]="item"
    >
      {{ item.name }}
      <mat-icon
        *ngIf="item.id && selectedId === item.id"
        class="check-icon"
        >check</mat-icon
      >
    </mat-option>
  </mat-autocomplete>
</div>
} @else {
<span
  class="px-[10px] text-over-line"
  [matTooltip]="getSelectOption"
  matTooltipPosition="below"
  matTooltipClass="custom-tooltip"
  >{{ getSelectOption }}</span
>
}
