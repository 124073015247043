import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiErrorResponse, ApiSuccessResponse, ObjectWithUnknowProperty } from 'src/app/core/models/common.model';
import {
  ListMasterData,
  ListMasterResponse,
  Master,
  MasterSystemColor,
  MasterSystemColorResponse,
  MasterSystemWashingIcon,
  RecordData
} from 'src/app/core/models/master.model';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  constructor(private http: HttpService) {}

  public getMasters({
    limit = 25,
    page = 0,
    orderBy = ''
  }: ListMasterData): Observable<ApiSuccessResponse<ListMasterResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-masters?page=${page}&limit=${limit}&orderBy=${orderBy}`, {}, environment.common_url);
  }

  public getMaster(
    masterId: string,
    search?: string,
    tagType?: number,
    categoryGroupType?: number,
    id?: string,
    type?: number
  ): Observable<ApiSuccessResponse<Array<Master> | ObjectWithUnknowProperty> | ApiErrorResponse> {
    let params = new HttpParams();

    if (search) {
      params = params.set('search', search);
    }

    if (tagType) {
      params = params.set('tagType', tagType);
    }

    if (categoryGroupType) {
      params = params.set('categoryGroupType', categoryGroupType);
    }
    if (id) {
      params = params.set('id', id);
    }

    if (type) {
      params = params.set('type', type);
    }

    return this.http.get(`tenant-masters/${masterId}?${params.toString()}`, {}, environment.common_url);
  }

  public getDropdownDataTenantMaster(
    masterId: string,
    activeStatus?: boolean
  ): Observable<ApiSuccessResponse<Array<Master> | ObjectWithUnknowProperty> | ApiErrorResponse> {
    let params = new HttpParams();
    if (activeStatus) {
      params = params.set('activeStatus', 1);
    }
    return this.http.get(`tenant-masters/dropdown/${masterId}?${params.toString()}`, {}, environment.common_url);
  }

  public actionMasterRecords(
    masterId: string,
    data: Array<RecordData>,
    currentVersion?: number
  ): Observable<ApiSuccessResponse<Array<Master> | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`tenant-masters/${masterId}`, { data, currentVersion }, environment.common_url);
  }

  public actionSystemRecords(
    masterSystem: string,
    data: Array<RecordData>,
    currentVersion?: number
  ): Observable<ApiSuccessResponse<Array<Master> | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(`system-master/${masterSystem}`, { data, currentVersion }, environment.common_url);
  }

  public getMasterSystems(
    masterSystem: string,
    params?: { [key: string]: string | number | boolean }
  ): Observable<ApiSuccessResponse<MasterSystemColorResponse | ObjectWithUnknowProperty> | ApiErrorResponse> {
    if (params) {
      const httpParams = new HttpParams({ fromObject: params });
      return this.http.get(`system-master/${masterSystem}?${httpParams.toString()}`, {}, environment.common_url);
    }

    return this.http.get(`system-master/${masterSystem}`, {}, environment.common_url);
  }

  public getTenantMaterialCategoriesLarge(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/material-categories/large`, {}, environment.common_url);
  }

  public getTenantMaterialCategoriesMedium(
    category1Id?: string
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    const params = new HttpParams({
      fromObject: {
        category1Id: category1Id || ''
      }
    });

    return this.http.get(`tenant-master/material-categories/medium?${params.toString()}`, {}, environment.common_url);
  }

  public getTenantMaterialCategoriesSmall(
    category2Id?: string
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    const params = new HttpParams({
      fromObject: {
        category2Id: category2Id || ''
      }
    });

    return this.http.get(`tenant-master/material-categories/small?${params.toString()}`, {}, environment.common_url);
  }

  public getBusinessPartners(
    businessPartnersAttributeId: string
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/business-partners/${businessPartnersAttributeId}`, {}, environment.common_url);
  }

  public postBusinessPartners(
    businessPartnersAttributeId: string,
    data: Array<RecordData>,
    currentVersion?: number
  ): Observable<ApiSuccessResponse<Array<Master> | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.post(
      `tenant-master/business-partners/${businessPartnersAttributeId}`,
      { data, currentVersion },
      environment.common_url
    );
  }

  public getTenantTag(
    categoryGroupType?: string
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    const params = new HttpParams({
      fromObject: {
        categoryGroupType: categoryGroupType || ''
      }
    });

    return this.http.get(`tenant-master/tags?${params.toString()}`, {}, environment.common_url);
  }

  public getTenantPrecautionTag(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/precaution-tags`, {}, environment.common_url);
  }

  public getTenantMasterCustomerItemList(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/custom-item/list`, {}, environment.common_url);
  }

  public getTenantMasterShowroomCustomerItemList(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/showroom-custom-item/list`, {}, environment.common_url);
  }

  public getTenantMasterWashIcon(): Observable<
    ApiSuccessResponse<MasterSystemWashingIcon | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/washing-icons`, {}, environment.common_url);
  }

  public getTenantMasterColor(): Observable<
    ApiSuccessResponse<MasterSystemColor | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/colors`, {}, environment.common_url);
  }

  public getTenantCountry(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/countries`, {}, environment.common_url);
  }

  public getTenantSeries(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/series`, {}, environment.common_url);
  }

  public getTenantMasterUnitForSale(): Observable<
    ApiSuccessResponse<MasterSystemColor | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/colors`, {}, environment.common_url);
  }

  public getTenantSeason(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/seasons`, {}, environment.common_url);
  }

  public getTenantWashingIcon(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/washing-icon-images`, {}, environment.common_url);
  }

  public getTenantCurrency(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/currencies`, {}, environment.common_url);
  }

  public getTenantMaterialComposition(
    activeStatus?: boolean
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(
      `tenant-master/material-compositions${activeStatus ? '?activeStatus=1' : ''}`,
      {},
      environment.common_url
    );
  }

  public getTenantUnit(
    type: number | string,
    isBuyer = false
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/unit?type=${type}&isBuyer=${isBuyer ? '1' : '0'}`, {}, environment.common_url);
  }

  public getTenantSize(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/sizes`, {}, environment.common_url);
  }

  public getTenantManagementClassification() {
    return this.http.get(`tenant-master/management-classification`, {}, environment.common_url);
  }

  public getTenantMasterCustomerItem(
    isValid = true
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/custom-item?isValid=${isValid ? '1' : '0'}`, {}, environment.common_url);
  }

  public getTenantMasterShowroomCustomerItem(
    isValid = true
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(
      `tenant-master/showroom-custom-item?isValid=${isValid ? '1' : '0'}`,
      {},
      environment.common_url
    );
  }

  public getTenantMasterCustomerItemDetail(
    id?: string
  ): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    const params = new HttpParams();

    if (id) {
      params.set('id', id);
    }

    return this.http.get(`tenant-master/custom-item/list/details?${params.toString()}`, {}, environment.common_url);
  }

  public getTenantBusinessPartnerAttribute(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/business-partners-attributes`, {}, environment.common_url);
  }

  public getTenantBusinessPartners(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/business-partners?fromSite=srm`, {}, environment.common_url);
  }

  public getTenantSizes(): Observable<ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse> {
    return this.http.get(`tenant-master/sizes`, {}, environment.common_url);
  }

  public getTenantProcessingType(): Observable<
    ApiSuccessResponse<RecordData | ObjectWithUnknowProperty> | ApiErrorResponse
  > {
    return this.http.get(`tenant-master/processing-types`, {}, environment.common_url);
  }
}
