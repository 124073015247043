import { Component, InjectionToken } from '@angular/core';

export const APP_INPUT_ERROR = new InjectionToken<ErrorComponent>('ErrorComponent');
export const ERROR_TAG = 'app-error';

@Component({
  selector: ERROR_TAG,
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  providers: [{ provide: APP_INPUT_ERROR, useExisting: ErrorComponent }]
})
export class ErrorComponent {}
