import { Observable, throwError } from 'rxjs';
import { ApiErrorResponse, ApiResponse, ApiSuccessResponse, ObjectWithUnknowProperty } from '../models/common.model';
import { TokenService } from '../services/token.service';

export abstract class HttpHelper {
  constructor(private tokenSerivce: TokenService) {}

  // Error handling
  protected handleError = (error: any): Observable<ApiErrorResponse> => {
    if (error.error instanceof ErrorEvent) {
      // Get FE error
      throwError(() => {
        return error.error.message;
      });
    }
    // Get BE error
    if (error?.status == 401 || error.error?.status == 401) {
      this.tokenSerivce.removeToken();
      location.href = '/auth/login';
    }

    // if (error?.status === 0 || error?.status === 500 || error.error?.status == 500) {
    //   location.href = '/500';
    // }

    return throwError(() => {
      return {
        success: false,
        ...error.error
      };
    });
  };

  protected parseResponse<T = ObjectWithUnknowProperty>(res: ApiResponse<T>): ApiSuccessResponse<T> {
    return <ApiSuccessResponse<T>>{
      success: true,
      message: res.message,
      data: res.response || res
    };
  }

  protected toJson(payload: Array<any> | object): string {
    return JSON.stringify(payload);
  }
}
