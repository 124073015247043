import {
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { APP_INPUT_ERROR, ErrorComponent } from '../../error/error.component';
import { MyErrorStateMatcher } from 'src/app/shared/state-matcher/error-state-matcher';

@Component({
  selector: 'app-text-area-comp',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppTextAreaComponent),
      multi: true
    }
  ]
})
export class AppTextAreaComponent implements ControlValueAccessor, OnInit {
  @Input() type: string | any = 'text';
  @Input() className: string | any = '';
  @Input() placeHolder: string | any = '';
  @Input() disabled: boolean = false;
  @Input() readAsLabel: boolean = false;
  @Input() isBorder: boolean = true;
  @Input() isApplyTrackerElement: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  isDisabled: boolean = false;

  @Input() error: boolean = false;

  @ContentChildren(APP_INPUT_ERROR, { descendants: true }) _errorChildren!: QueryList<ErrorComponent>;

  onChange: any = () => {};

  onTouch: any = () => {};

  value = '';

  ngAfterContentInit() {
    this.isDisabled = this.disabled;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  _control!: NgControl;
  matcher!: MyErrorStateMatcher;
  constructor(private injector: Injector) {}
  ngOnInit(): void {
    this._control = this.injector.get(NgControl);
    this.matcher = new MyErrorStateMatcher(this._control);
  }

  writeValue(val: string) {
    this.value = val;
  }

  onModelChange(e: string) {
    this.value = e;
    this.onChange(e);
  }

  onHandleClear(event: Event) {
    this.onModelChange('');
    this.onClear.emit();
  }
  onHandleBlur(event: any) {
    this.blur.emit(event);
    this.onModelChange(this.value); // Register Focus Event
  }
  onHandleFocus(event: any) {
    this.focus.emit(event);
  }
}
