<mat-form-field
  class="flex-1 bg-white justify-center search w-full"
  [class]="className"
>
  <div
    matPrefix
    class="searchIcon ml-[10px]"
  >
    <mat-icon>search</mat-icon>
  </div>

  <input
    #search
    matInput
    class="form-input outline-none"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    (keypress)="onHandleKeyPress($event)"
    [placeholder]="placeHolder"
    [disabled]="disabled"
    [spellcheck]="false"
    (blur)="onHandleBlur($event)"
  />

  <div
    class="closeIcon mr-[10px] flex items-center"
    *ngIf="!!value"
    (click)="onHandleClear($event)"
    (mousedown)="onClickClearButton($event)"
    matSuffix
  >
    <mat-icon>close</mat-icon>
  </div>
</mat-form-field>
