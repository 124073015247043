import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent {
  constructor(private router: Router) {}
  onBack() {
    this.router.navigate(['/auth/login']);
  }
}
