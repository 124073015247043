/* eslint-disable prettier/prettier */
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { TokenService } from 'src/app/core/services/token.service';
import { Router } from '@angular/router';
import { Tenant, TenantResponse } from 'src/app/core/models/tenant.model';
import { LoggedUserResponse } from 'src/app/core/models/auth.model';
import { ApiSuccessResponse, ObjectWithUnknowProperty, ApiErrorResponse } from 'src/app/core/models/common.model';
import { TenantService } from 'src/app/modules/tenant/services/tenant.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { HeadService } from 'src/app/core/services/head.service';
import { environment } from 'src/environments/environment';
import { DomainComponent } from '../domain/domain.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-logged-dashboard',
  templateUrl: './logged-dashboard.component.html',
  styleUrls: ['./logged-dashboard.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class LoggedDashboardComponent implements OnInit {
  @ViewChild('drawer1') drawer1: any;
  @ViewChild(DomainComponent) domainComponent: DomainComponent;

  isClosed = false;
  canManageMaterial = false;
  canManageShowroom = false;
  isHandset$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => {
      let attr, mode;
      if (result.matches) {
        attr = 'dialog';
        mode = 'over';
      } else {
        attr = 'navigation';
        mode = 'side';
      }
      return {
        matches: result.matches,
        attr,
        mode
      };
    }),
    shareReplay()
  );
  tenants: Array<Tenant> = [];
  selectedTenantId = '';
  urlLogo = '';
  buyerUrl = `https://user.${environment.rootDomain}`;
  isBuyer = true;
  preconnectUrls = ['https://fonts.googleapis.com', 'https://fonts.gstatic.com'];
  googleFronts = [
    'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap',
    'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp',
    'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200'
  ];

  version = `${environment.version}`;

  isOpenSwitchTenantSaler = false;
  isOpenSwitchTenantBuyer = false;
  isOpenSwitchTenantPlm = false;

  haveTenantBelongSrm = true;
  haveTenantBelongPlm = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private tokenService: TokenService,
    private router: Router,
    private tenantService: TenantService,
    private utilsService: UtilsService,
    private headService: HeadService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.head();
    this.isHandset$.pipe(distinctUntilChanged()).subscribe((val) => {
      if (val.matches) {
        this.isClosed = true;
      }
    });

    this.canManageMaterial = this.tokenService.canManageMaterial();

    this.canManageShowroom = this.tokenService.canManageShowroom();

    this.getScreenData();

    const authUser = JSON.parse(this.getLoggedEmail());

    const plmDomains = authUser.tenants.filter((tenant: any) => tenant.subDomain?.includes('plm'));
    const srmDomains = authUser.tenants.filter((tenant: any) => !tenant.subDomain?.includes('plm'));

    this.haveTenantBelongPlm = plmDomains.length > 0;
    this.haveTenantBelongSrm = srmDomains.length > 0;
  }

  head() {
    this.headService.createLinkForCanonicalURL();
    this.preconnectUrls.forEach((url) => {
      this.headService.createLinkHead(url, 'preconnect');
    });
    this.googleFronts.forEach((font) => {
      this.headService.createLinkHead(font, 'stylesheet');
    });
  }

  getScreenData() {
    this.tokenService.getUserObservable().subscribe({
      next: (json: string) => {
        const user: LoggedUserResponse = JSON.parse(json);
        this.tenants = user.tenants;
        if (!this.tenants) {
          return;
        }
        this.selectedTenantId = this.tokenService.getTenant();
        if (this.selectedTenantId) {
          this.isBuyer = false;
          this.tenantService.getBasicInformation(this.selectedTenantId).subscribe({
            next: (res: ApiSuccessResponse<TenantResponse | ObjectWithUnknowProperty> | ApiErrorResponse) => {
              const resData: any = res.data;
              this.urlLogo = this.utilsService.getCloudFrontUrl(resData.logoUrl, false);
              this.urlLogo = this.urlLogo ? this.urlLogo + `?v=${Date.now()}` : '';
              this.tokenService.setTenantLogo(this.urlLogo);
            },
            error: (error: ApiErrorResponse) => {
              console.log(error);
            }
          });
        } else {
          this.tokenService.setTenantLogo('assets/images/supplyzfashion_RGB.svg');
        }
      }
    });
  }

  getLogo() {
    if (this.tokenService.getTenantLogo()) {
      const url = this.tokenService.getTenantLogo();
      return url;
    } else {
      return '';
    }
  }

  getLoggedEmail(): string {
    return this.tokenService.getUser();
  }

  switchRegisterProduct(event: MouseEvent) {
    event.preventDefault();
    this.switchToBuyer('order/register-purchase-order');
  }

  switchPageOrder(event: MouseEvent) {
    event.preventDefault();
    this.switchToBuyer('order/purchase-order-list');
  }
  switchShowroomProduct(event: MouseEvent) {
    event.preventDefault();
    this.switchToBuyer('showroom/showroom-materials');
  }

  switchToBuyer(path: string) {
    if (this.isBuyer) {
      this.router.navigateByUrl(path);
      return;
    }

    const url = `${this.buyerUrl}/${path}`;

    const expirationDate = new Date();

    expirationDate.setTime(expirationDate.getTime() + 30 * 1000);

    this.cookieService.set('jwt_token', this.tokenService.get(), expirationDate, '/', `.${environment.rootDomain}`);

    window.open(url, '_blank');
  }

  handleCloseSidebar() {
    this.isOpenSwitchTenantSaler = false;

    this.isOpenSwitchTenantBuyer = false;
    this.isOpenSwitchTenantPlm = false;
    this.isClosed = true;
    this.drawer1.close();
  }
  handleOpen() {
    this.drawer1.open();
    this.isClosed = false;
  }
}
