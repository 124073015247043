<div class="page-notFound bg-[#F8F8F9] w-full h-full flex items-center justify-center flex-wrap flex-col">
  <div class="mb-5 logo">
    <img
      class="w-64"
      src="assets/images/supplyzfashion_RGB.svg"
    />
  </div>
  <div class="content bg-[#ffffff] flex flex-col gap-9 px-[45px] py-[30px] w-[480px]">
    <div class="font-bold text-base">500 Internal Server Error</div>
    <div class="font-bold text-[20px] leading-[1.6]">サーバーエラーが発生しました。</div>
    <div class="font-normal flex flex-col text-base">
      <span>サーバーの問題でお探しのページを表示できません。</span>
      <span>再度時間をおいてからアクセスしてください。</span>
    </div>
    <button
      (click)="onBack()"
      class="btn bg-[#515151] text-white px-5 py-2 rounded-[4px] mt-[15px] font-bold"
    >
      ログイン画面へ戻る
    </button>
  </div>
</div>
