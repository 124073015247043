<div class="flex flex-row justify-between items-center w-full">
  <div class="text-sm">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="flex items-center gap-4">
    <div
      class="flex items-center cursor-pointer z-[999] gap-3"
      (click)="toggleNotice()"
      #btnNotice
    >
      <div class="w-9 h-9 relative">
        <mat-icon class="!w-9 !h-9 text-4xl">mail_outline</mat-icon>
        <span
          *ngIf="totalNoticeUnread"
          class="notice-count"
          >{{ totalNoticeUnread }}</span
        >
      </div>

      <span class="text-base">
        {{ 'topbar.profile.notice' | translate }}
      </span>
    </div>

    <div
      #notice
      class="fixed top-[50px] bottom-0 w-full md:w-[30rem] notice flex flex-col"
    >
      <div class="flex notice-header items-center justify-between gap-[10px]">
        <span class="font-bold">
          {{ 'topbar.profile.noticeList' | translate }}
        </span>
        <div class="flex flex-1 items-center justify-end gap-x-4 gap-y-2">
          <span
            class="decoration-black underline cursor-pointer text-sm"
            (click)="markAllAsRead()"
            >{{ 'topbar.profile.markAllAsRead' | translate }}</span
          >
          <mat-checkbox
            [(ngModel)]="isUnread"
            (ngModelChange)="unReadChange()"
            class="text-sm"
          >
            {{ 'topbar.profile.unreadOnly' | translate }}
          </mat-checkbox>
          <mat-icon
            class="close-icon cursor-pointer"
            (click)="toggleNotice()"
            >clear</mat-icon
          >
        </div>
      </div>
      <div
        class="notice-list flex-1"
        #noticeList
      >
        <div
          class="notice-list__item flex items-center gap-3"
          *ngFor="let item of listNotifications"
          (click)="handleClickNotice(item)"
        >
          <div class="flex-1 overflow-hidden content">
            <p class="font-bold overflow-hidden text-ellipsis whitespace-nowrap">
              {{ item.title }}
            </p>
            <p
              class="overflow-hidden text-ellipsis whitespace-nowrap"
              [innerHTML]="item.content"
            ></p>
          </div>
          <span
            *ngIf="item.hasRead"
            class="status"
            >{{ 'topbar.profile.read' | translate }}</span
          >
          <span
            *ngIf="!item.hasRead"
            class="status unread"
            >{{ 'topbar.profile.unread' | translate }}</span
          >
          <span>{{ item.createdAt | date : 'yyyy/MM/dd' }}</span>
        </div>
        <div
          class="flex items-center justify-center p-2"
          *ngIf="noticeLoading"
        >
          <mat-icon>
            <mat-progress-spinner
              color="accent"
              diameter="18"
              mode="indeterminate"
            ></mat-progress-spinner>
          </mat-icon>
        </div>
      </div>
    </div>

    <div
      [matMenuTriggerFor]="menu"
      class="flex items-center gap-3 justify-end cursor-pointer"
    >
      <img
        *ngIf="!avatar"
        class="rounded-full w-10 h-10"
        src="assets/images/icon_user.svg"
      />
      <img
        *ngIf="avatar"
        class="rounded-full w-10 h-10"
        [src]="avatar"
      />
      <span
        class="text-base max-w-[213px] truncate"
        [matTooltip]="getFullName"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      >
        {{ getFullName }}
      </span>
    </div>

    <mat-menu
      class="menu-profile top-3 bg-[#f4f4f4] rounded-md !max-w-sm !shadow-none"
      #menu="matMenu"
    >
      <div class="flex flex-row items-center px-8 py-4 w-[246px] h-[104px] gap-[16px]">
        <div>
          <img
            *ngIf="!avatar"
            class="rounded-full w-10 h-10"
            src="assets/images/icon_user.svg"
          />
          <img
            *ngIf="avatar"
            class="rounded-full w-10 h-10"
            [src]="avatar"
          />
        </div>
        <div class="flex flex-col font-normal px-4 text-[14px]">
          <div class="cursor-pointer">
            <a
              class="hover:underline"
              routerLink="/profile/setting"
              >{{ 'topbar.profile.settingProfile' | translate }}</a
            >
          </div>
          <div
            *ngIf="canManageMaterial"
            class="py-2 cursor-pointer"
          >
            <a
              *ngxPermissionsOnly="[roles.SYSTEM_ADMIN, roles.MANAGER]"
              class="hover:underline"
              (click)="navigateTenantSetting()"
              >{{ 'topbar.profile.tenantSetting' | translate }}</a
            >
          </div>
          <div class="cursor-pointer">
            <a
              class="hover:underline"
              (click)="logout()"
              >{{ 'topbar.profile.logout' | translate }}</a
            >
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
