import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError, map, subscribeOn } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiErrorResponse, ApiResponse, ApiSuccessResponse } from '../models/common.model';
import { TokenService } from './token.service';
import { HttpHelper } from '../helpers/http.helper';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HttpService extends HttpHelper {
  constructor(private http: HttpClient, private tokenService: TokenService) {
    super(tokenService);
  }

  // Fix bug Token doesnt update on Login

  getHeadersOptions(options?: any): { headers: HttpHeaders } {
    const localStartOfDay = moment().startOf('day');
    const utcStartOfDay = moment.utc([localStartOfDay.year(), localStartOfDay.month(), localStartOfDay.date()]);
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get()}`,
        'x-tenant-id': this.tokenService.getTenant() || '',
        'x-time-now': utcStartOfDay.toISOString(),
        ...options
      })
    };
  }

  public getMe(domain: string, endpoint: string, headers?: any): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .get<ApiResponse>(`${domain}/${endpoint}`, this.getHeadersOptions(headers))
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public get(endpoint: string, headers?: any, domain?: string): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .get<ApiResponse>(`${domain ?? environment.api_base_url}/${endpoint}`, this.getHeadersOptions(headers))
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public post(
    endpoint: string,
    data: Array<any> | object,
    domain?: string
  ): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .post<ApiResponse>(
        `${domain ?? environment.api_base_url}/${endpoint}`,
        this.toJson(data),
        this.getHeadersOptions()
      )
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public put(
    endpoint: string,
    data: Array<any> | object,
    domain?: string
  ): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .put<ApiResponse>(
        `${domain ?? environment.api_base_url}/${endpoint}`,
        this.toJson(data),
        this.getHeadersOptions()
      )
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public delete(endpoint: string, domain?: string): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .delete<ApiResponse>(`${domain ?? environment.api_base_url}/${endpoint}`, this.getHeadersOptions())
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public patch(
    endpoint: string,
    data: Array<any> | object,
    domain?: string
  ): Observable<ApiSuccessResponse | ApiErrorResponse> {
    return this.http
      .patch<ApiResponse>(
        `${domain ?? environment.api_base_url}/${endpoint}`,
        this.toJson(data),
        this.getHeadersOptions()
      )
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public upload(endpoint: string, data: FormData): Observable<ApiSuccessResponse | ApiErrorResponse> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.get()}`,
        'x-tenant-id': this.tokenService.getTenant() || ''
      })
    };

    return this.http
      .post<ApiResponse>(`${environment.common_url}/${endpoint}`, data, options)
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }

  public uploadSrm(endpoint: string, data: FormData): Observable<ApiSuccessResponse | ApiErrorResponse> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.get()}`,
        'x-tenant-id': this.tokenService.getTenant() || ''
      })
    };

    return this.http
      .post<ApiResponse>(`${environment.api_base_url}/${endpoint}`, data, options)
      .pipe(map(this.parseResponse), catchError(this.handleError));
  }
}
