import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ValidatorDirective),
      multi: true
    }
  ]
})
export class ValidatorDirective implements Validator {
  @Input() validatorFns: ValidatorFn[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    for (let idx = 0; idx < this.validatorFns.length; idx++) {
      const func = this.validatorFns[idx];
      const err = func(control);
      if (err) {
        return err;
      }
    }
    return null;
  }
}
